.inputBorder{
    border: 0.572917px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 2.29167px rgba(0, 0, 0, 0.25);
}

@media (min-width:1024px){
    .input{
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
}