.loginForm {
  background: #ffffff;

  /* stroke fade */
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.transPin {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.inputOTP {
  border-radius: 2.865px;
  border: 0.573px solid var(--stroke-fade, rgba(0, 0, 0, 0.3));
  background: #fff;
}
.inputOTPError {
  border-radius: 2.865px;
  border: 0.573px solid var(--stroke-fade, rgba(238, 63, 63, 0.987));
  background: #fff;
}

.inputOTP:focus {
  outline: 1px solid rgba(0, 0, 0, 0);
  border: 0.573px solid #d166ff;
  /* border-radius: 2.865px; */
}
