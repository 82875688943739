#CardPayment {
  background: linear-gradient(91deg, #fbff75 0.49%, #ff7b9c 99.51%);
}

#AddNewCard {
  background: linear-gradient(
    91deg,
    #9e7cff 0.48%,
    rgba(235, 111, 255, 0.86) 99.51%
  );
}

#Card {
  box-shadow: 0px 0.33333px 3.33333px 0px rgba(0, 0, 0, 0.25);
}

#ExistingBankPage {
  background: linear-gradient(
    91deg,
    #7bff70 0.48%,
    rgba(77, 244, 255, 0.86) 99.51%
  );
}

#USD {
  box-shadow: 0px 0px 6.66667px 0px rgba(0, 0, 0, 0.25);
}

.popUp1,
.popUp01 {
  position: absolute;
  bottom: 0%;
  height: 400px;
  width: 90%;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.cvvpop,
.cvvpop01 {
  margin: 0 auto;
  padding: 0% 20px 5% 20px;
  width: 90%;
  height: 270px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.currencypopup,
.currencypopup1 {
  margin: 0 auto;
  padding: 2% 20px;
  width: 90%;
  height: 270px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

@media (min-width: 768px) {
  .popUp1 {
    position: absolute;
    top: 0%;
    height: 400px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .popUp01 {
    position: absolute;
    top: 0%;
    height: 400px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .cvvpop {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 50%;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .cvvpop01 {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 50%;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .currencypopup {
    margin: 0 auto;
    padding: 2% 20px;
    width: 50%;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .currencypopup1 {
    padding: 2% 20px;
    width: 50%;
    height: 40%;
    position: absolute;
    top: 28.5%;
    border-radius: 12px;
    margin-left: 20%;
  }
}

@media (min-width: 845px) {
  .default-card {
    height: 130px;
  }

  .success-default-card {
    height: 80px;
    width: 80px;
  }

  .delete-card {
    width: 150px;
    height: 120px;
  }

  .confirm-delete-card {
    height: 110px;
  }

  .okay {
    margin-top: 10%;
  }

  .pinpopup {
    left: 36%;
  }

  .popUp-style {
    width: 50%;
    height: 70%;
  }

  .cvvpop01 {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 50%;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .secure1 {
    width: 55%;
  }

  .secure {
    width: 58%;
  }

  .default-card {
    width: 100px;
    height: 100px;
  }

  .currencypopup {
    margin: 0 auto;
    padding: 2% 20px;
    width: 50%;
    height: 310px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }
}

@media (min-width: 1020px) {
  .popUp1 {
    position: absolute;
    top: 0%;
    height: 400px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .popUp01 {
    position: absolute;
    top: 0%;
    height: 400px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .okay {
    margin-top: 10%;
  }

  .secure1 {
    width: 52.5%;
  }

  .pinpopup {
    left: 35%;
  }

  .card-background {
    left: 70%;
  }

  .expiry-date {
    margin-top: 5%;
  }

  .popUp-style {
    width: 50%;
  }

  .delete-card {
    width: 100px;
    height: 100px;
  }

  .cvvpop {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 40%;
    height: 310px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .cvvpop01 {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 40%;
    height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .secure {
    width: 55%;
  }

  .currencypopup {
    margin: 0 auto;
    padding: 2% 20px;
    width: 40%;
    height: 310px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .currencypopup1 {
    padding: 2% 20px;
    width: 40%;
    height: 40%;
    position: absolute;
    top: 30%;
    border-radius: 12px;
    margin-left: 20%;
  }
}

@media (width: 1024px) {
  .currencypopup {
    margin: 0 auto;
    padding: 2% 20px;
    width: 50%;
    height: 310px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .currencypopup1 {
    padding: 2% 20px;
    width: 40%;
    height: 40%;
    position: absolute;
    top: 30%;
    border-radius: 12px;
    /* margin-left: 20%; */
  }

  .default-card {
    width: 200px;
    height: 150px;
  }

  .delete-card {
    width: 150px;
    height: 150px;
  }

  .success-default-card {
    height: 100px;
    width: 100px;
  }

  .popUp1 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .popUp01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }
}

@media (min-width: 1250px) {
  .default-card {
    width: 200px;
    height: 180px;
  }

  .success-default-card {
    height: 150px;
    width: 150px;
  }

  .delete-card {
    width: 150px;
    height: 150px;
  }

  .currencypopup {
    margin: 0 auto;
    padding: 2% 20px;
    width: 50%;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .cvvpop {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 40%;
    height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .cvvpop01 {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 40%;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .popUp1 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .popUp01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }
}

@media (min-width: 1440px) {
  .okay {
    margin-top: 10%;
  }

  .card-background {
    left: 72.5%;
  }

  .popUp-style {
    width: 60%;
  }

  .cvvpop {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 30%;
    height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .cvvpop01 {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 35%;
    height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .secure {
    width: 55%;
  }

  .currencypopup {
    padding: 2% 20px;
    width: 40%;
    height: 40%;
    position: absolute;
    top: 30%;
    border-radius: 12px;
  }

  .currencypopup1 {
    padding: 2% 20px;
    width: 40%;
    height: 40%;
    position: absolute;
    top: 30%;
    border-radius: 12px;
    margin-left: 20%;
  }

  .delete-card {
    width: 170px;
    height: 170px;
  }

  .success-default-card {
    height: 150px;
    width: 150px;
  }

  .popUp1 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .popUp01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }
}

@media (min-width: 1500px) {
  .currencypopup {
    padding: 2% 20px;
    width: 30%;
    height: 40%;
    position: absolute;
    top: 30%;
    border-radius: 12px;
  }

  .currencypopup1 {
    padding: 2% 20px;
    width: 35%;
    height: 40%;
    position: absolute;
    top: 30%;
    border-radius: 12px;
    margin-left: 17%;
  }

  .cvvpop {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 30%;
    height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .cvvpop01 {
    margin: 0 auto;
    padding: 0% 20px 2% 20px;
    width: 35%;
    height: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .popUp1 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .popUp01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .default-card {
    width: 200px;
    height: 150px;
  }

  .success-default-card {
    height: 130px;
    width: 130px;
  }

  .delete-card {
    width: 150px;
    height: 150px;
  }
}
