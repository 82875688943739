.sidebar::-webkit-scrollbar {
  display: none;
}

.dashboard {
  display: flex;
  align-items: center;
  margin: 10% auto;
  display: flex;
  width: 125px;
  padding: 4% 0 4% 4%;
  gap: 4%;
  border-radius: 2.897px;
  background: rgba(242, 250, 255, 0.1);
  backdrop-filter: blur(5.589743614196777px);
}

.balance {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.balance1 {
  width: 100%;
  height: 167.787px;
  border-radius: 8.32px;
  padding: 3%;
}
.balance2 {
  width: 100%;
  height: 100%;
  border-radius: 8.32px;
  padding:4%;
  display : flex;
  flex-direction: column;
  justify-content : center;
  gap : 25px;
}

.viewWallet {
  display: flex;
  padding: 10.92px 20.84px;
  float: right;
  gap: 7.84px;
  border-radius: 18.193px;
  /* background: #04177f; */
  color: #fff;
  
  line-height: normal;
}

.walletText {
  font-size: 11.093px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  margin-top: 7%;
}

.selected {
  padding: 3px;
  border-radius: 10.193px;
  background: var(--boxes, rgba(146, 171, 254, 0.18));
  height: 23px;
  font-size: 9.933px;
  font-weight: 600;
  line-height: normal;
  width: 50px;
  outline: none;
  font-weight: bolder;
  /* z-index: 99px; */
}

.currency {
  border-radius: 2.88px 2.88px 0px 0px;
  background: #fff;
  box-shadow: 0px 1.4400001764297485px 3.6000008583068848px 0px
    rgba(0, 0, 0, 0.25);
}

.viewBalance {
  display: flex;
  margin-top: 6%;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.button {
  display: flex;
  width: 50%;
  padding: 4.333px;
  justify-content: center;
  align-items: center;
  gap: 3.333px;
  border-radius: 4px;
}

.button p {
  color: #fff;
  font-size: 6.333px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.sidebar {
  height: 100vh;
}

/* ================ TABLET VIEW ============== */
@media (min-width: 768px) and (max-width: 1180px) {
  .dashboard {
    width: 157px;
    gap: 6px;
  }

  .walletText {
    font-size: 26.093px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    margin-top: 6%;
  }

  .balance {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .balance1 {
    width: 100%;
    height: 280px;
    border-radius: 8.32px;
    padding: 3%;
  }
  .balance2 {
    width: 100%;
    height: 280px;
    border-radius: 8.32px;
    padding: 3%;
  }

  .viewWallet {
    display: flex;
    padding: 8.92px 9.84px;
    float: right;
    gap: 7.84px;
    border-radius: 10.193px;
    /* background: #04177f; */
    color: #fff;
    font-size: 10.33px;
    font-weight: 600;
    line-height: normal;
  }

  .viewBalance {
    display: flex;
    margin-top: 4%;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  .NGN {
    width: 50px;
    height: 25px;
    padding: 5.92px;
    gap: 7.84px;
    border-radius: 10.193px;
    background: rgba(146, 171, 254, 0.18);
    font-size: 9.933px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .button {
    display: flex;
    width: 110px;
    padding: 7.333px 11.667px;
    justify-content: center;
    align-items: center;
    gap: 9.333px;
    border-radius: 8px;
  }

  .button p {
    color: #fff;
    font-size: 13.333px;
    font-weight: 900;
    line-height: normal;
  }

  .selected  {
    padding: 3px;
    border-radius: 10.193px;
    background: var(--boxes, rgba(146, 171, 254, 0.18));
    height: 23px;
    font-size: 9.933px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 50px;
    outline: none;
    font-weight: bolder;
  }
}

/* =========Ipad Air=========== */
@media (min-width: 820px) and (max-width: 1180px) {
  .balance1 {
    height: 500px;
    /* background-color: black; */
  }
}

/* =================Surface Pro 7================ */
@media (min-width: 912px) and (max-width: 1368px) {
  .sidebar {
    width: 250px;
    height: 100vh;
  }

  .navSide {
    gap: 110px;
  }

  .topbar {
    gap: 195px;
  }

  .viewWallet {
    font-size: 20px;
  }

  .dashboard {
    width: 209px;
    gap: 9px;
  }

  .viewBalance {
    display: flex;
    margin-top: 4%;
    gap: 59px;
    justify-content: center;
    align-items: center;
  }

  .balance1 {
    width: 100%;
    height: 350px;
    border-radius: 8.32px;
    padding: 3%;
  }

  .balance2 {
    height: 100%;
    gap:15px;
  }

  .GVA {
    font-size: 24px;
  }

  .virtualaccounttxt {
    font-size: 19px;
  }

  .selected {
    padding: 8.6px;
    border-radius: 14.193px;
    height: 33px;
    font-size: 13.733px;
    width: 69px;
  }

  .eye {
    font-size: 30px;
  }

  .fcp {
    /* margin-top: 5%; */
    gap: 100px;
  }

  .fcp2 {
    font-size: 14px;
    border-radius: 15px;
  }

  .button {
    gap: 10px;
    width: 25%;
  }

  .button p {
    font-size: 14.162px;
  }

  .button img {
    width: 25px;
    height: 25px;
  }

  .logout {
    /* margin-top: 190%; */
    font-size: 24px;
    align-items: center;
  }

  .logouttxt {
    font-size: 18px;
  }
}

/* ===================Surface Duo======================== */
@media (min-width: 540px) and (max-width: 720px) {
  .sidebar {
    height: 100vh;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .topbar {
    gap: 100px;
  }

  .topbarcom {
    gap: 80px;
  }

  .viewWallet {
    font-size: 10px;
  }

  .viewBalance {
    display: flex;
    margin-top: 4%;
    gap: 59px;
    justify-content: center;
    align-items: center;
  }

  .balance1 {
    height: 220px;
  }

  .balance2 {
    height: 220px;
  }

  .fcp2 {
    font-size: 10px;
  }

  .button {
    gap: 10px;
    padding: 8px 0;
  }

  .button p {
    font-size: 10px;
  }
}

/* =================== DESKTOP VIEW================ */
@media (min-width: 1024px) {
  .dashboard {
    width: 220px;
    gap: 10px;
    cursor: pointer;
    border-radius: 10px;
  }

  .button {
    display: flex;
    width: 20%;
    padding-top: 21.226px;
    padding-bottom: 21.226px;
    justify-content: center;
    align-items: center;
    gap: 19.226px;
    flex-shrink: 0;
    border-radius: 15.872px;
  }

  .button p {
    color: #fff;
    font-size: 21.162px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .balance {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .balance1 {
    width: 47%;
    height: 320.081px;
    flex-shrink: 0;
    padding: 2%;
    border-radius: 15.872px;
  }
  .balance2 {
    width: 47%;
    height: 320.081px;
    flex-shrink: 0;
    padding: 2%;
    border-radius: 15.872px;
  }

  .viewWallet {
    display: flex;
    padding: 10.84px 15px;
    float: right;
    border-radius: 19.193px;
    /* background: #04177f; */
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
  }

  .walletText {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    margin-top: 10%;
  }

  .NGN {
    width: 80px;
    height: 35px;
    padding: 7.92px;
    gap: 14.84px;
    border-radius: 19.193px;
    background: rgba(146, 171, 254, 0.18);
    font-size: 13.933px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .viewBalance {
    display: flex;
    margin-top: 6%;
    gap: 25px;
    justify-content: center;
    align-items: center;
  }

  .myselect .custom-select {
    outline: none;
  }

  .selected {
    padding: 5.6px;
    border-radius: 14.193px;
    background: var(--boxes, rgba(146, 171, 254, 0.18));
    height: 33px;
    font-size: 10.733px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 55px;
    outline: none;
    font-weight: bolder;
  }

  .navSide {
    gap: 50px;
  }
}
