#DataTopUp {
  background: linear-gradient(
    92deg,
    #ffb57f 31.38%,
    rgba(235, 111, 255, 0.86) 98.66%
  );
}

#Transaction {
  background: linear-gradient(92deg, #ffda44 4.68%, #ff748d 55.04%);
}

#DataBundle {
  background: linear-gradient(92deg, #0cbc8b 4.68%, #367cff 55.04%);
}

.custom-placeholder::placeholder {
  text-align: center;
}
.input {
  box-shadow: 0px 0px 4px 0px #00000040;
}

.code::-webkit-scrollbar {
  display: none;
}

.scroll-bar::-webkit-scrollbar {
  display: none;
}

.AirtimeTops1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 300px;
  height: 100%;
}

.mainGrid {
  margin: 0 auto;
  width: 100%;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.mainGrid {
  display: grid;
  grid-template-columns: 40% 40%;
  width: 100%;
  column-gap: 60px;
}

.mainGridCol {
  display: grid;
  grid-template-columns: 40% 40%;
  width: 100%;
  column-gap: 60px;
}

.code {
  position: absolute;
  height: 500px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.network {
  position: absolute;
  margin-top: 4px;
  border: none;
  box-shadow: 0px 4px 10px 0px #00000040;
  border-radius: 5px;
  transition: height 0.3s ease;
  background: #fff;
  z-index: 999;
}

.Datatopup {
  display: flex;
  padding: 6.968px 12.508px 4.5px 6.968px;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 3.974px;
  box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
}

.Datapopup {
  position: absolute;
  top: 34%;
  width: 90%;
  height: 269px;
  border-radius: 8px;
  box-shadow: 0px 0px 9.217082977294922px 0px rgba(0, 0, 0, 0.25);
}

#Network {
  box-shadow: 0px 0px 1.33333px 0px rgba(0, 0, 0, 0.25);
}

.confirm,
.confirm01 {
  position: absolute;
  bottom: 0%;
  height: 520px;
  width: 90%;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.confirmEdit,
.confirmEdit01 {
  position: absolute;
  bottom: 0%;
  height: 400px;
  width: 90%;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

/* 
.continueState, .continueState01 {
  position: absolute;
  bottom: 0%;
  height: 400px;
  width: 90%;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
} */

.confirm2,
.confirm02 {
  bottom: 0%;
  position: absolute;
  width: 90%;
  height: 250px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.AirtimeConfirm,
.AirtimeConfirm1 {
  bottom: 0%;
  position: absolute;
  width: 90%;
  height: 310px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.confirm3,
.confirm03 {
  position: absolute;
  width: 90%;
  height: 520px;
  padding-bottom: 10px;
  bottom: 0;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}

.datapopup1,
.datapopup01,
.datapopup011 {
  margin: 0 auto;
  padding: 5% 20px;
  width: 90%;
  height: 280px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.confirm200,
.confirm002 {
  position: absolute;
  width: 90%;
  height: 250px;
  bottom: 0;
}

@media (max-width: 425px) {
  .AirtimeTops1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    height: 100vh;
  }

  .mainGrid {
    margin: 0 auto;
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .mainGrid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .mainGridCol {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .Datatopup {
    box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  /* .img {
    margin-right: 125px;
    margin-left: 125px;
  } */

  .confirm002 {
    width: 50%;
    height: 300px;
    top: 0;
    margin-left: 20%;
  }

  .confirm200 {
    width: 50%;
    height: 300px;
    top: 0;
  }

  .confirmEdit {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 60%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirmEdit01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .code01 {
    width: 60%;
  }

  .code1 {
    width: 50%;
    margin-left: 20%;
  }

  .img {
    margin-left: 17%;
    width: 80%;
    height: 80%;
    margin-top: 30px;
  }

  .Airtime-coversion {
    margin-left: 17%;
    width: 80%;
    height: 80%;
    margin-top: 30px;
  }

  .img2,
  .img3 {
    margin-left: 30%;
    width: 40%;
    height: 40%;
  }

  .currency-img {
    width: 50%;
    height: 50%;
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 430px;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .datapopup01,
  .datapopup011 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 430px;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .confirm {
    position: absolute;
    top: 0%;
    height: 572px;
    width: 60%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm01 {
    position: absolute;
    top: 0%;
    height: 572px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm2 {
    top: 0%;
    width: 45%;
    top: 0;
    position: absolute;
    height: 350px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm02 {
    top: 0%;
    position: absolute;
    width: 45%;
    height: 350px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .AirtimeConfirm {
    margin: 0 auto;
    padding: 2% 20px;
    width: 45%;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 20%;
  }

  .AirtimeConfirm1 {
    margin: 0 auto;
    padding: 2% 20px;
    width: 45%;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .confirm3 {
    position: absolute;
    width: 50%;
    height: 600px;
    padding-bottom: 10px;
    top: 0;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm03 {
    position: absolute;
    top: 0%;
    height: 572px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .mainGrid {
    margin: 0 auto;
    width: 100%;
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .mainGrid {
    display: grid;
    grid-template-columns: 40% 40%;
    width: 100%;
    column-gap: 60px;
  }

  .mainGridCol {
    display: grid;
    grid-template-columns: 40% 40%;
    width: 100%;
    column-gap: 60px;
  }

  .mainGridCol {
    margin: auto 0;
    border-radius: 6px;
    width: 100%;
    height: 30px;
    border: none;
    box-shadow: 0px 0px 4px 0px #00000040;
  }

  .save {
    margin-bottom: 40%;
    width: 60%;
    height: 40%;
  }

  .confirm1 {
    height: 35%;
    margin-bottom: 35%;
  }

  .Datatopup {
    box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
  }

  .amount1 {
    padding-right: 5px;
  }

  /* .transaction-table21 {
    gap: 8%;
  } */
}

/* ===================Tablet view ================ */
@media (min-width: 820px) and (max-width: 1180px) {
  .Datapopup {
    position: absolute;
    top: 40%;
    width: 55%;
    height: 380px;
    border-radius: 8px;
    box-shadow: 0px 0px 9.217082977294922px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm {
    position: absolute;
    top: 0%;
    height: 620px;
    width: 45%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .code {
    height: 500px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .save {
    width: 60%;
    height: 40%;
  }

  .continue {
    margin-top: 15%;
  }

  .Datatopup {
    box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 4% 20px;
    width: 430px;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  /* .transaction-table {
    gap: 11%;
  }

  .transaction-table1 {
    gap: 8.5%;
  }

  .amount {
    padding-right: 5px;
  }

  .amount1 {
    padding-right: 5px;
  } */
}

@media (min-width: 845px) {
  .confirm {
    position: absolute;
    top: 0%;
    height: 620px;
    width: 45%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .transaction-table {
    gap: 9.3%;
  }

  .transaction-table1 {
    gap: 9%;
  }

  .transaction-table2 {
    gap: 6%;
  }

  .transaction-table21 {
    gap: 9%;
  }

  .amount {
    padding-right: 5px;
  }

  .amount1 {
    padding-right: 6px;
  }

  .confirm01 {
    position: absolute;
    top: 0%;
    height: 620px;
    width: 45%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm2 {
    top: 0%;
    width: 45%;
    bottom: 0%;
    position: absolute;
    height: 350px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm02 {
    top: 0%;
    position: absolute;
    width: 45%;
    height: 350px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .AirtimeConfirm {
    margin: 0 auto;
    padding: 2% 20px;
    width: 45%;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 20%;
  }

  .AirtimeConfirm1 {
    margin: 0 auto;
    padding: 2% 20px;
    width: 45%;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .confirm3 {
    position: absolute;
    width: 50%;
    height: 600px;
    padding-bottom: 10px;
    top: 0;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm03 {
    position: absolute;
    top: 0%;
    height: 572px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .img {
    margin-left: 17%;
    width: 80%;
    height: 80%;
    margin-top: 30px;
  }

  .Airtime-coversion {
    margin-left: 17%;
    width: 80%;
    height: 80%;
    margin-top: 30px;
  }

  .img2 {
    margin-left: 30%;
    width: 45%;
    height: 45%;
    margin-top: 1%;
  }

  .img3 {
    margin-left: 30%;
    width: 40%;
    height: 40%;
    margin-top: 1%;
  }

  .currency-img {
    width: 50%;
    height: 50%;
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 430px;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .datapopup01,
  .datapopup011 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 430px;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .code01 {
    width: 55%;
  }

  .code1 {
    width: 50%;
    margin-left: 20%;
  }

  .confirmEdit {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 60%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirmEdit01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm200 {
    position: absolute;
    width: 50%;
    height: 300px;
    top: 0;
  }

  .confirm002 {
    width: 50%;
    height: 300px;
    top: 0;
    margin-left: 20%;
  }
}

/* ==================Surface Pro 7================ */
@media (min-width: 912px) and (max-width: 1368px) {
  .Datatopup {
    display: flex;
    padding: 8.594px 28.645px 8.375px 8.595px;
    align-items: center;
    gap: 203.073px;
    border-radius: 6.875px;
    box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
  }

  .Datapopup {
    position: absolute;
    top: 37%;
    width: 50%;
    height: 360px;
    border-radius: 8px;
  }

  .mobile-desktop {
    margin-top: 5px;
  }

  .confirm {
    position: absolute;
    padding-bottom: 50px;
    top: 0%;
    height: 500px;
    width: 55%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm01 {
    position: absolute;
    padding-bottom: 50px;
    top: 0%;
    height: 500px;
    width: 55%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }
}

@media (min-width: 967px) {
  .transaction-table {
    gap: 10.4%;
  }

  .transaction-table21 {
    gap: 10%;
  }

  .transaction-table2 {
    gap: 7.6%;
  }

  .confirm {
    position: absolute;
    padding-bottom: 20px;
    top: 0%;
    height: 500px;
    width: 55%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm01 {
    position: absolute;
    padding-bottom: 20px;
    top: 0%;
    height: 500px;
    width: 55%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .Airtime-coversion {
    margin-left: 17%;
    width: 70%;
    height: 70%;
    margin-top: 30px;
  }
}

@media (min-width: 1020px) and (max-width: 1180px) {
  /* .img {
    margin-right: auto;
    margin-left: auto;
    margin-top: 1%;
    margin-bottom: -5%;
  } */

  .confirm2 {
    top: 0%;
    width: 45%;
    bottom: 0%;
    position: absolute;
    height: 350px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm02 {
    top: 0%;
    position: absolute;
    width: 45%;
    height: 350px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm200 {
    position: absolute;
    width: 40%;
    height: 300px;
    top: 0;
  }

  .confirm002 {
    width: 40%;
    height: 300px;
    top: 0;
    margin-left: 20%;
  }

  .confirmEdit01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirmEdit {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .code1 {
    width: 45%;
    margin-left: 20%;
  }

  .code01 {
    width: 50%;
  }

  .img {
    margin-left: 17%;
    width: 80%;
    height: 80%;
    margin-top: 30px;
  }

  .Airtime-coversion {
    margin-left: 17%;
    width: 60%;
    height: 60%;
    margin-top: 30px;
  }

  .img2 {
    margin-left: 30%;
    width: 45%;
    height: 45%;
    margin-top: 1%;
  }

  .img3 {
    margin-left: 30%;
    width: 40%;
    height: 40%;
    margin-top: 1%;
  }

  .currency-img {
    width: 50%;
    height: 50%;
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 430px;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .datapopup01,
  .datapopup011 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 430px;
    height: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .confirm {
    position: absolute;
    top: 0%;
    height: 620px;
    width: 45%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm01 {
    position: absolute;
    top: 0%;
    height: 620px;
    width: 45%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm03 {
    position: absolute;
    top: 0%;
    height: 572px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .proceed {
    margin-top: 10%;
    margin-left: 22%;
  }

  /* .transaction {
    gap: 11%;
  } */

  .transaction2 {
    margin-top: 20px;
  }

  .amount {
    padding-right: 5px;
  }

  .amount1 {
    padding-right: 5px;
  }

  .transaction-table {
    gap: 10.8%;
  }

  .transaction-table1 {
    gap: 10.3%;
  }

  .transaction-table2 {
    gap: 8%;
  }

  .transaction-table21 {
    gap: 10.3%;
  }
}

/* ================Desktop View======================= */
@media (min-width: 1024px) {
  .Datatopup {
    display: flex;
    padding: 15px 50px 15px 15px;
    align-items: center;
    gap: 354px;
    border-radius: 12px;
    box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
    border-width: 1px;
  }

  .Datapopup {
    width: 562px;
    height: 500px;
    position: absolute;
    top: 5%;
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .datapopup01,
  .datapopup011 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .confirm {
    position: absolute;
    top: 0%;
    height: 560px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .code {
    height: 500px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .transaction-table {
    gap: 6.5%;
  }

  .transaction-table1 {
    gap: 9%;
  }

  .transaction-table2 {
    gap: 6.5%;
  }

  .transaction-table21 {
    gap: 7%;
  }

  .confirm200 {
    position: absolute;
    width: 40%;
    height: 380px;
    top: 0;
  }

  .confirm002 {
    width: 40%;
    height: 380px;
    top: 0;
    margin-left: 20%;
  }

  .img {
    margin-left: 10%;
    width: 100%;
    height: 100%;
    margin-top: 30px;
  }

  .Airtime-coversion {
    margin-left: 17%;
    width: 60%;
    height: 100%;
    margin-top: 30px;
  }

  .img2 {
    margin-left: 30%;
    width: 50%;
    height: 50%;
    margin-top: 1%;
  }

  .img3 {
    margin-left: 30%;
    width: 48%;
    height: 48%;
    margin-top: 1%;
  }

  .confirm2 {
    top: 0%;
    width: 40%;
    bottom: 0%;
    position: absolute;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm02 {
    top: 0%;
    position: absolute;
    width: 40%;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .AirtimeConfirm {
    margin: 0 auto;
    padding: 2% 20px;
    width: 40%;
    height: 405px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .AirtimeConfirm1 {
    margin: 0 auto;
    padding: 2% 20px;
    width: 40%;
    height: 405px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }
}

@media (min-width: 1250px) {
  .transaction-table {
    gap: 8.5%;
  }

  .transaction-table1 {
    gap: 9%;
  }

  .transaction-table2 {
    gap: 9%;
  }

  .transaction-table21 {
    gap: 9%;
  }

  .amount1 {
    padding-right: 15px;
  }

  .img {
    margin-left: 10%;
    width: 100%;
    height: 100%;
    margin-top: 30px;
  }

  .Airtime-coversion {
    margin-left: 10%;
    width: 90%;
    height: 100%;
    margin-top: 30px;
  }

  .currency-img {
    width: 60%;
    height: 60%;
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .datapopup01,
  .datapopup011 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .code01 {
    width: 50%;
  }

  .code1 {
    width: 45%;
    margin-left: 20%;
  }

  .confirmEdit {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm200,
  .confirm002 {
    position: absolute;
    width: 35%;
    height: 400px;
    top: 0;
  }

  .confirmEdit01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm2 {
    top: 0%;
    width: 40%;
    bottom: 0%;
    position: absolute;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm02 {
    top: 0%;
    position: absolute;
    width: 40%;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm03 {
    position: absolute;
    top: 0%;
    height: 620px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm {
    position: absolute;
    top: 0%;
    height: 560px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm01 {
    position: absolute;
    top: 0%;
    height: 560px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm3 {
    position: absolute;
    width: 50%;
    height: 700px;
    padding-bottom: 10px;
    top: 0;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 1440px) {
  .transaction-table {
    gap: 9.7%;
  }

  .transaction-table1 {
    gap: 9.9%;
  }

  .transaction-table2 {
    gap: 9.7%;
  }

  .transaction-table21 {
    gap: 10%;
  }

  .proceed {
    margin-bottom: 20%;
    margin-left: 27%;
  }

  .confirm2 {
    top: 0%;
    width: 40%;
    bottom: 0%;
    position: absolute;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm02 {
    top: 0%;
    position: absolute;
    width: 40%;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm03 {
    position: absolute;
    top: 0%;
    height: 720px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .img {
    height: 100%;
    width: 100%;
    margin-left: 10%;
    margin-top: 5%;
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .datapopup01 {
    margin: 0 auto;
    padding: 3% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .datapopup011 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .code01 {
    width: 50%;
  }

  .code1 {
    width: 40%;
    margin-left: 20%;
  }

  .confirmEdit {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirmEdit01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm200,
  .confirm002 {
    position: absolute;
    width: 35%;
    height: 400px;
    top: 0;
  }

  .confirm3 {
    position: absolute;
    width: 50%;
    height: 700px;
    padding-bottom: 10px;
    top: 0;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm {
    position: absolute;
    top: 0%;
    height: 560px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm01 {
    position: absolute;
    top: 0%;
    height: 560px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }
}

@media (min-width: 1500px) {
  .transaction-table {
    gap: 10.8%;
  }

  .transaction-table1 {
    gap: 10.3%;
  }

  .transaction-table2 {
    gap: 11%;
  }

  .transaction-table21 {
    gap: 11%;
  }

  .img {
    height: 100%;
    width: 100%;
    margin-left: 10%;
    margin-top: 4%;
  }

  .Airtime-coversion {
    margin-left: 10%;
    width: 70%;
    height: 80%;
    margin-top: 30px;
  }

  .img2 {
    margin-left: 30%;
    width: 50%;
    height: 50%;
    margin-top: 1%;
  }

  .img3 {
    margin-left: 30%;
    width: 48%;
    height: 48%;
    margin-top: 1%;
  }

  .datapopup1 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
  }

  .datapopup01 {
    margin: 0 auto;
    padding: 0% 20px;
    width: 30%;
    height: 420px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .datapopup011 {
    margin: 0 auto;
    padding: 1% 20px;
    width: 562px;
    height: 480px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    margin-left: 10%;
  }

  .code01 {
    width: 40%;
  }

  .code1 {
    width: 40%;
    margin-left: 20%;
  }

  .confirmEdit {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirmEdit01 {
    position: absolute;
    top: 0%;
    height: 500px;
    width: 35%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm200,
  .confirm002 {
    position: absolute;
    width: 30%;
    height: 400px;
    top: 0;
  }

  .confirm2 {
    top: 0%;
    width: 40%;
    bottom: 0%;
    position: absolute;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm02 {
    top: 0%;
    position: absolute;
    width: 40%;
    height: 405px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm3 {
    position: absolute;
    width: 50%;
    height: 620px;
    padding-bottom: 10px;
    top: 0;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm03 {
    position: absolute;
    top: 0%;
    height: 620px;
    width: 50%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }

  .confirm {
    position: absolute;
    top: 0%;
    height: 560px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .confirm01 {
    position: absolute;
    top: 0%;
    height: 560px;
    width: 40%;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    margin-left: 20%;
  }
}
