* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.transferMoneyPop::-webkit-scrollbar {
    display: none;
}
.balanceMoneyPop::-webkit-scrollbar {
    display: none;
}
.successfulTwo::-webkit-scrollbar {
    display: none;
}
.successfulThree::-webkit-scrollbar {
    display: none;
}
.successfulFour::-webkit-scrollbar {
    display: none;
}

/* Banner Section */

.successfulTwo {
    position: absolute;
    bottom: 0%;
    height: 470px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}
.successfulThree {
    position: absolute;
    bottom: 0%;
    height: 300px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}
.successfulFour {
    position: absolute;
    bottom: 0%;
    height: 330px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}
.transferMoneyPop {
    position: absolute;
    height: 505px;
    flex-shrink: 0;
    bottom: 0%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}
.balanceMoneyPop {
    position: absolute;
    height: 505px;
    flex-shrink: 0;
    bottom: 0%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}
.inputPin {
    position: absolute;
    bottom: 0%;
    height: 250px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}
.receipt {
    height: 100%;
    flex-shrink: 0;
    border-radius: 10px;
    margin-bottom: 50%;
    background: #fff;
    box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}
.AirtimeTops {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 300px;
    height: 100%;
}
.AirtimeTops1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 300px;
    height: 100%;
}

.airtimeTop {
    display: flex;
    flex-direction: column;
    gap: 60px;
}
.airtimeTop .banner {
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(92.3deg, #73FF9A 26.51%, #6EDCFF 82.31%);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding: 34px 40px;
}
.banner .bannerText {
    width: 90%;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.bannerText h2 {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;    
    color: #000000;
}
.bannerText p {
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #000000;
}
.banner .bannerImage {
    margin: auto 0;
}
.banner .bannerImage img {
    position: relative;
    bottom: 0;
}

/* Previous Recipients */

.airtimeTop .containFlex {
    width: 100%;
    height: 51px;
    display: flex;
    gap: 60px;
    position: relative;
    margin: 0;
}
.FlexPut {
    margin: auto 0;
    border-radius: 10px;
    width: 50%;
    height: 51px;
    border: none;
    box-shadow: 0px 0px 4px 0px #00000040;
}
.FlexPut .conPut {
    width: 95%;
    position: relative;
    top: 13px;
    left: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.conPut h2 {
    width: 177px;
    height: 21px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.FlexPut .FlexImg img {
    width: 24px;
    height: 24px;
}
.containFlex1 {
    width: 100%;
    position: relative;
    margin: 0 auto;
    top: 0;
}
.FlexPut1 {
    margin: auto 0;
    border-radius: 10px;
    width: 46.5%;
    height: 51px;
    border: none;
    background: #04177F;
    box-shadow: 0px 0px 4px 0px #00000040;
    color: #FFF;
    cursor: pointer;
}
.FlexPut1 .conPut1 {
    width: 95%;
    height: 24px;
    position: relative;
    top: 13px;
    left: 10px;
    display: flex;
}
.FlexPut1 .conPut1 h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
}
.FlexPut1 .FlexImg1 img {
    width: 24px;
    height: 24px;
}

/* Main input section */

.airtimeTop .mainGrid {
    margin: 0 auto;
    width: 100%;
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.mainGrid .mainGridCol {
    display: grid;
    grid-template-columns: 46.5% 46.5%;
    width: 100%;
    column-gap: 60px;
}
.input {
    margin: auto 0;
    border-radius: 10px;
    width: 100%;
    height: 51px;
    border: none;
    box-shadow: 0px 0px 4px 0px #00000040;
}
.input .output {
    width: 95%;
    height: 24px;
    position: relative;
    top: 14px;
    padding-right: 5px;
    left: 10px;
    display: flex;
    justify-content: space-between;
}
.output input {
    width: 100%;
}
.input .output1 {
    width: 95%;
    height: 24px;
    position: relative;
    top: 14px;
    left: 10px;
    display: flex;
    justify-content: space-between;
}
.output1 input {
    width: 100%;
}
.output1 h2 {
    width: 100%;
    color: #7C7C7C;
}
.input .output2 {
    width: 95%;
    height: 24px;
    position: relative;
    top: 14px;
    left: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.output2 h2 {
    width: 100%;
    color: #7C7C7C;
}
.output2 .labelInput {
    width: 100%;
}
.output2 input {
    width: 100%;
}
.input .head2 {
    font-weight: 800;
    color: #000000;
}
.btnDrop img {
    width: 24px;
    height: 24px;
}
.colDown {
    position: absolute;
    margin-top: 4px;
    border: none;
    box-shadow: 0px 4px 10px 0px #00000040;
    border-radius: 5px;
    width: 46.7%;
    transition: height .3s ease;
    background: #FFF;
    z-index: 999;
}
.labelInput {
    display: flex;
    gap: 3px;
    list-style: none;
    width: 100%;
}
.labelInput .network {
    width: 24px;
    height: 24px;
}
.netList {
    border-bottom-width: 1px;
    cursor: pointer;
    display: flex;
    gap: 2px;
    padding: 8px;
    margin: auto 0;
    width: 100%;
    transition: .3s;
    z-index: 99;
}
.netList1 {
    border-bottom-width: 1px;
    cursor: pointer;
    display: flex;
    gap: 2px;
    padding: 8px;
    margin: auto 0;
    width: 100%;
    transition: .3s;
}
.netList .netImage {
    width: 24px;
    overflow: hidden;
}
.netImage .NoImage {
    object-fit: cover;
    width: 24px;
}
.netList1 .netName1 {
    font-size: 16px;
    font-weight: 800;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #219250;
}
.netList .netName {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #7C7C7C;
}
.input1 {
    display: flex;
    justify-content: space-between;
    padding: 14px 15px 12px 13px;
    margin: auto 0;
    border-radius: 10px;
    width: 100%;
    height: 51px;
    border: none;
    box-shadow: 0px 0px 4px 0px #00000040;
    cursor: pointer;
}
.input1 span {
    width: 100%;
    color: #7C7C7C;
}
.input1 .span2 {
    color: #219250;
    font-weight: 800;
    width: 100%;
}
.input1 .labelInput {
    width: 100%;
}
.input1 .head9 {
    width: 100%;
    color: #7C7C7C;
}
.input2 {
    display: flex;
    justify-content: space-between;
    padding: 14px 10px 12px;
    margin: auto 0;
    border-radius: 10px;
    width: 100%;
    height: 51px;
    border: none;
    box-shadow: 0px 0px 4px 0px #00000040;
}
.input2 h2 {
    width: 100%;
}
.disc img {
    margin: auto 0;
    width: 34px;
}
.input .phone {
    outline: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    color: #000000;
}
.input .phones {
    outline: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    color: #000000;
}
.phone::placeholder {
    font-weight: 400;
}
.phones::placeholder {
    font-weight: 400;
}
.call img {
    margin: auto 0;
    width: 24px;
}
.span3 {
    font-size: 12px;
    font-weight: 700;
    line-height: 15.6px;
    letter-spacing: 0em;
    color: #04177F;
    cursor: pointer;
}
.span4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    letter-spacing: 0em;
    color: #7C7C7C;
}
.head3 {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 20.8px;
    padding-bottom: 3%;
}
.labelInput .head4 {
    margin: auto 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #7C7C7C;
}

/* Add recipients */

.add {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 22.23px;
    margin: 0 auto;
    position: relative;
    top: 0;
}
.add h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
    letter-spacing: 0em;
    text-align: left;
}
.containFlex2 {
    width: 100%;
    height: 38px;
    position: relative;
    margin: 0 auto;
    top: -30px;
}
.containFlex3 {
    width: 100%;
    height: 38px;
    position: relative;
    margin: 0 auto;
    top: -40px;
}
.FlexPut2 {
    padding: 8px 16px;
    margin: auto 0;
    border-radius: 10px;
    width: 163px;
    height: 38px;
    font-size: 16px;
    border: none;
    background: #04177F;
    box-shadow: 0px 0px 4px 0px #00000040;
    color: #FFF;
    border-radius: 12px;
}

/* Contact aspect of the airtime page */

.help {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    height: 20px;
    display: flex;
    gap: 26px;
}
.help h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 15.6px;
    letter-spacing: 0em;
}
.help .btnContact {
    background: #04177F;
    padding: 5px 15px 10px 15px;
    height: 20px;
    border-radius: 9px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 10.4px;
    letter-spacing: 0em;
    position: relative;
    bottom: 3px;
}

/* CONFIRMATION MESSAGE */

.confirmPro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 0.3);
    z-index: 300;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}
.processFirm {
    background-color: #fff;
    border: none;
    margin-left: 20%;
    margin-right: 20%;
    padding-bottom: 20px;
    border-radius: 8px;
    position: relative;
    border-radius: 11.5px;
}
.modalHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-bottom: 10px solid #04177F;
    padding-right: 12px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.closeBtn {
    width: 20px;
    height: 20px;
    padding-bottom: 5px;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #92ABFE;
    color: #7C7C7C;
    border: none;
}
.modalHeaderX {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    padding-top: 10px;
}
.closeBtnX {
    width: 20px;
    height: 20px;
    padding-bottom: 5px;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #92ABFE;
    color: #7C7C7C;
    border: none;
}
.modalContent {
    padding: 15px;
    padding-left: 12px;
}
.modalContent .Tran {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.Tran h2 {
    font-size: 14px;
}
.modalContent h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
}
.section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
}
.section .head2 {
    color: #7C7C7C;
}
.flexWide {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
}
.flexGap {
    display: flex;
    gap: .25rem;
}
.nairaFlex {
    width: 100%;
    padding: 0 12px;
    height: 100px;
    display: flex;
    align-items: center;
    gap: 2px;
    background: #F6F7F7;    
}
.flexBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}
.flexBtn button {
    width: 163px;
    height: 38px;
    border-radius: 12px;
    padding: 10px 15px;
    background-color: #04177F;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
}

@media (min-width: 820px) and (max-width: 1180px) {  
    .transferMoneyPop {
      height: 620px;
      bottom: auto;
      top: auto;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    }
    .balanceMoneyPop {
      height: 620px;
      bottom: auto;
      top: auto;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    }
    .inputPin {
        bottom: auto;
        top: auto;
        width: 481.25px;
        height: 350px;
    }
    .successfulTwo {
        bottom: auto;
        top: auto;
        width: 60%;
        height: 600px;
        border-radius: 15px;
    }
    .successfulThree {
        bottom: auto;
        top: auto;
        width: 60%;
        height: 450px;
        border-radius: 15px;
    }
    .successfulFour {
        bottom: auto;
        top: auto;
        width: 60%;
        height: 450px;
        border-radius: 15px;
    }
}

/* ==================Laptop View ============================ */
@media (min-width: 1024px) {
    .transferMoneyPop {
      height: 560px;
      bottom: auto;
      top: auto;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    }
    .balanceMoneyPop {
      height: 560px;
      bottom: auto;
      top: auto;
      border-radius: 20px;
      background: #fff;
      box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    }
    .inputPin {
        height: 405px;
        position: absolute;
        bottom: auto;
        top: auto;
        border-radius: 20px;
    }
    .successfulTwo {
        position: absolute;
        bottom: auto;
        top: auto;
        border-radius: 15px;
        height: 550px;
        box-shadow: 0px 0px 16.952381610870361px 0px rgba(0, 0, 0, 0.25);
    }
    .successfulThree {
        position: absolute;
        bottom: auto;
        top: auto;
        border-radius: 15px;
        height: 450px;
        box-shadow: 0px 0px 16.952381610870361px 0px rgba(0, 0, 0, 0.25);
    }
    .successfulFour {
        position: absolute;
        bottom: auto;
        top: auto;
        border-radius: 15px;
        height: 450px;
        box-shadow: 0px 0px 16.952381610870361px 0px rgba(0, 0, 0, 0.25);
    }
    .receipt {
        border-radius: 20px;
    }
}

@media (max-width: 768px) {
    .airtimeTop .banner {
        width: 100%;
        margin: 0 auto;
        background: linear-gradient(92.3deg, #73FF9A 26.51%, #6EDCFF 82.31%);
        border-radius: 11.5px;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
    }
    .banner .bannerText {
        width: 100%;
        margin: auto 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .bannerText h2 {
        width: 100%;
        font-size: 13.75px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;    
        color: #000000;
    }
    .bannerText p {
        width: 100%;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        color: #000000;
    }
    .banner .bannerImage {
        margin: auto 0;
    }
    .banner .bannerImage img {
        width: 80%;
        position: relative;
        bottom: 0;
        left: 10px;
    }
    .airtimeTop .containFlex {
        width: 100%;
        height: 51px;
        display: flex;
        gap: 60px;
        position: relative;
        top: 0;
        margin: 0 auto;
    }
    .FlexPut {
        margin: auto 0;
        border-radius: 6px;
        width: 50%;
        height: 30px;
        border: none;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .FlexPut .conPut {
        width: 95%;
        height: 15px;
        position: relative;
        top: 8px;
        left: 5px;
        display: flex;
        justify-content: space-between;
        gap: 0;
    }
    .conPut h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
    }
    .FlexPut .FlexImg img {
        width: 14px;
        height: 14px;
    }
    .containFlex1 {
        width: 100%;
        position: relative;
        margin: 0 auto;
        top: -10px;
        left: 0;
    }
    .FlexPut1 {
        margin: auto 0;
        border-radius: 6px;
        width: 43%;
        height: 30px;
        border: none;
        background: #04177F;
        box-shadow: 0px 0px 4px 0px #00000040;
        color: #FFF;
    }
    .FlexPut1 .conPut1 {
        width: 95%;
        height: 12px;
        position: relative;
        top: 9px;
        left: 5px;
        display: flex;
        justify-content: space-between;
    }
    .FlexPut1 .conPut1 h2 {
        height: 12px;
        font-size: 8.5px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
    }
    .FlexPut1 .FlexImg1 img {
        width: 14px;
        position: relative;
        bottom: 5px;
    }
    .airtimeTop .mainGrid {
        margin: 0 auto;
        width: 100%;
        position: relative;
        top: 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .mainGrid .mainGridCol {
        display: grid;
        grid-template-columns: 43% 43%;
        width: 100%;
        column-gap: 60px;
    }
    .mainGridCol .input {
        margin: auto 0;
        border-radius: 6px;
        width: 100%;
        height: 30px;
        border: none;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .input .output {
        width: 95%;
        height: 15px;
        position: relative;
        top: 8px;
        left: 5px;
        display: flex;
        justify-content: space-between;
    }
    .output input {
        width: 100%;
    }
    .input .output1 {
        width: 95%;
        height: 15px;
        position: relative;
        top: 9px;
        left: 5px;
        display: flex;
        justify-content: space-between;
    }
    .output1 input {
        width: 100%;
    }
    .output1 h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em; 
        color: #7C7C7C;
    }
    .output1 .disc {
        position: relative;
        top: 4px;
        left: 0;
    }
    .input .output2 {
        width: 95%;
        height: 15px;
        position: relative;
        top: 10px;
        left: 5px;
        display: flex;
        justify-content: space-between;
    }
    .output2 h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
    }
    .output2 input {
        width: 100%;
    }
    .output2 .btnDrop {
        position: relative;
        top: -3px;
    }
    .input .head2 {
        font-weight: 800;
        color: #000000;
        font-size: 10px;
    }
    .btnDrop img {
        width: 14px;
        height: 14px;
    }
    .colDown {
        position: absolute;
        margin-top: 4px;
        border: none;
        box-shadow: 0px 4px 10px 0px #00000040;
        border-radius: 5px;
        width: 43%;
        transition: height .3s ease;
        background: #FFF;
        z-index: 999;
    }
    .labelInput {
        display: flex;
        gap: 3px;
        list-style: none;
        width: 100%;
    }
    .labelInput .network {
        width: 14px;
        height: 14px;
    }
    .netList {
        border-bottom-width: 1px;
        cursor: pointer;
        display: flex;
        gap: 2px;
        padding: 8px;
        margin: auto 0;
        width: 100%;
        transition: .3s;
    }
    .netList1 {
        border-bottom-width: 1px;
        cursor: pointer;
        display: flex;
        gap: 2px;
        padding: 8px;
        margin: auto 0;
        width: 100%;
        transition: .3s;
    }
    .netList .netImage {
        width: 14px;
        overflow: hidden;
    }
    .netImage .NoImage {
        object-fit: cover;
        width: 14px;
    }
    .netList1 .netName1 {
        font-size: 10px;
        font-weight: 800;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #219250;
    }
    .netList .netName {
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #7C7C7C;
    }
    .input1 {
        display: flex;
        justify-content: space-between;
        margin: auto 0;
        border-radius: 6px;
        width: 100%;
        height: 30px;
        border: none;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .input1 .head9 {
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0em;
        color: #7C7C7C;
        position: relative;
        top: -3px;
    }
    .input1 .head4 {
        position: relative;
        top: -4px;
        left: -10px;
    }
    .input1 button {
        position: relative;
        top: -4px;
        left: 10px;
        width: 14px;
    }
    .input1 .span2 {
        color: #219250;
        font-weight: 800;
        font-size: 10px;
        line-height: 12px;
        position: relative;
        bottom: 4px;
        left: -10px;
    }
    .input1 span {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        position: relative;
        bottom: 4px;
        left: -10px;
    }
    .input1 .btnDrop {
        position: relative;
        top: -5px;
        left: 10px;
    }
    .input2 {
        display: flex;
        justify-content: space-between;
        margin: auto 0;
        border-radius: 6px;
        width: 100%;
        height: 30px;
        border: none;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .input2 h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        position: relative;
        top: -5px;
        left: -10px;
    }
    .disc img {
        margin: auto 0;
        width: 14px;
        position: relative;
        top: -4px;
        left: 0;
    }
    .input .phone {
        outline: none;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #000000;
    }
    .input .phones {
        outline: none;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #000000;
    }
    .phone::placeholder {
        font-weight: 400;
    }
    .call img {
        margin: auto 0;
        width: 14px;
        position: relative;
        top: 2px;
    }
    .span3 {
        font-size: 8px;
        font-weight: 700;
        line-height: 10px;
        letter-spacing: 0em;
        color: #04177F;
    }
    .span4 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #7C7C7C;
    }
    .head3 {
        color: #000000;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        padding-bottom: 3%;
    }
    .head4 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        color: #7C7C7C;
        position: relative;
    }
    .labelInput .head4 {
        margin: auto 0;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        color: #7C7C7C;
    }
    .add {
        display: flex;
        gap: 11px;
        width: 100%;
        height: 13.23px;
        margin: 0 auto;
        position: relative;
        top: 0;
    }
    .add h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
    }
    .containFlex2 {
        width: 100%;
        height: 38px;
        position: relative;
        top: 0;
        margin: 0 auto;
    }
    .containFlex3 {
        width: 100%;
        height: 38px;
        position: relative;
        top: 0;
        margin: 0 auto;
    }
    .FlexPut2 {
        margin: auto 0;
        padding: 3px 9px 9px;
        border-radius: 6px;
        width: 96px;
        height: 22px;
        font-size: 10px;
        border: none;
        background: #04177F;
        box-shadow: 0px 0px 4px 0px #00000040;
        color: #FFF;
    }
    .help {
        margin: 0 auto;
        width: 100%;
        justify-content: center;
        height: 20px;
        display: flex;
        gap: 15px;
        padding-bottom: 10%;
    }
    .help h2 {
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
    }
    .help .btnContact {
        background: #04177F;
        padding: 5px 15px 10px 15px;
        height: 20px;
        border-radius: 9px;
        color: #fff;
        font-size: 8px;
        font-weight: 500;
        line-height: 10.4px;
        letter-spacing: 0em;
        position: relative;
        bottom: 3px;
    }
    .airtimeTop {
        padding-bottom: 5%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .confirmPro {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 0.3);
        z-index: 300;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
    }
    .processFirm {
        background-color: #fff;
        border: none;
        margin-left: 20%;
        margin-right: 20%;
        padding-bottom: 20px;
        border-radius: 8px;
        position: relative;
        border-radius: 11.5px;
    }
    .modalHeader {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        border-bottom: 10px solid #04177F;
        padding-right: 12px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .closeBtn {
        width: 20px;
        height: 20px;
        padding-bottom: 5px;
        border-radius: 50%;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #92ABFE;
        color: #7C7C7C;
        border: none;
    }
    .modalContent {
        padding: 15px;
        padding-left: 12px;
    }
    .modalContent .Tran {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .Tran h2 {
        font-size: 8px;
    }
    .modalContent h2 {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
    }
    .section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2%;
    }
    .section .head2 {
        color: #7C7C7C;
    }
    .flexWide {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px;
    }
    .flexGap {
        display: flex;
        gap: .25rem;
    }
    .nairaFlex {
        width: 100%;
        padding: 0 12px;
        height: 60px;
        display: flex;
        align-items: center;
        gap: 2px;
        background: #F6F7F7;    
    }
    .flexBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
    }
    .flexBtn button {
        width: 96px;
        height: 23px;
        border-radius: 7px;
        padding: 5.87px 9px;
        background-color: #04177F;
        color: #FFF;
        font-size: 8px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
    }
}

@media (max-width: 425px) {
    .airtimeTop .banner {
        width: 100%;
        margin: 0 auto;
        background: linear-gradient(92.3deg, #73FF9A 26.51%, #6EDCFF 82.31%);
        height: 90px;
        padding: 0;
        border-radius: 7px;
        display: flex;
        justify-content: space-between;
    }
    .banner .bannerText {
        width: 100%;
        position: relative;
        top: 0;
        margin: auto 0;
        left: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .bannerText h2 {
        width: 100%;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;    
        color: #000000;
    }
    .bannerText p {
        width: 100%;
        font-size: 7px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        color: #000000;
    }
    .banner .bannerImage {
        margin: auto 0;
    }
    .banner .bannerImage img {
        position: relative;
        top: 0;
        left: 30px;
    }
    .airtimeTop .containFlex {
        width: 100%;
        display: flex;
        gap: 60px;
        position: relative;
        top: 0;
        left: 0;
        margin: 0 auto;
        padding-top: 0%;
    }
    .FlexPut {
        margin: auto 0;
        border-radius: 3.67px;
        width: 100%;
        height: 25px;
        border: none;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .FlexPut .conPut {
        width: 95%;
        margin: auto;
        position: relative;
        top: 4.68px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .conPut h2 {
        width: 80px;
        height: 9px;
        font-size: 10px;
        font-weight: 600;
        line-height: 9px;
        letter-spacing: 0em;
        text-align: left;
    }
    .FlexPut .FlexImg img {
        width: 13px;
        height: 13px;
    }
    .containFlex1 {
        width: 100%;
        height: 30px;
        position: relative;
        margin: 0 auto;
        right: 0px;
        top: -10px;
    }
    .containFlex1 .FlexPut1 {
        margin: auto;
        border-radius: 10px;
        width: 100%;
        height: 30px;
        border: none;  
        border-radius: 4px;
        padding: 3.24px 8.09px;
        background: #04177F;
        box-shadow: 0px 0px 4px 0px #00000040;
        color: #FFF;
    }
    .containFlex1 .conPut1 {
        position: relative;
        top: 5px;
        left: 0;
        margin: auto;
        align-items: center; 
        display: flex;
        justify-content: center;
        text-align: center;
        gap: 4px;
        width: 58%;
    }
    .FlexPut1 .conPut1 h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        position: relative;
        left: 0;
    }
    .FlexPut1 .FlexImg1 img {
        width: 13px;
        height: 13px;
        position: relative;
        top: .1px;
    }
    .airtimeTop .mainGrid {
        margin: 0 auto;
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .mainGrid .mainGridCol {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }
    .input {
        margin: auto 0;
        border-radius: 3.67px;
        width: 100%;
        height: 30px;
        border: 0.5;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .input1 span {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        position: relative;
        bottom: 4px;
        right: 5px;
        color: #7C7C7C;
        width: 100%;
    }
    .input .output {
        width: 95%;
        margin: 0 auto;
        height: 24px;
        position: relative;
        top: 4px;
        left: 0;
        display: flex;
        justify-content: space-between;
    }
    .output input {
        width: 220px;
    }
    .output img {
        position: relative;
        top: 5px;
    }
    .input .output1 {
        width: 95%;
        height: 24px;
        position: relative;
        top: 10px;
        left: 5px;
        display: flex;
        justify-content: space-between;
    }
    .output1 .disc {
        position: relative;
        top: -3px;
        left: 6px;
    }
    .output1 input {
        width: 100%;
    }
    .input .output2 {
        width: 95%;
        margin: 0 auto;
        height: 24px;
        position: relative;
        top: 10px;
        left: 3px;
        display: flex;
        justify-content: space-between;
    }
    .output2 .btnDrop {
        position: relative;
        bottom: 6px;
        left: 10px;
        top: -5px;
        width: 24px;
    }
    .output1 h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
    }
    .output2 h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        color: #7C7C7C;
        width: 100%;
    }
    .output2 .labelInput {
        width: 100%;
    }
    .output2 input {
        width: 100px;
    }
    .input .head2 {
        font-weight: 800;
        color: #000000;
        font-size: 10px;
    }
    .btnDrop img {
        width: 12px;
        height: 12px;
    }
    .colDown {
        position: absolute;
        margin-top: 4px;
        border: none;
        box-shadow: 0px 4px 10px 0px #00000040;
        border-radius: 5px;
        width: 100%;
        transition: height .3s ease;
        background: #FFF;
        z-index: 99;
    }
    .input1 .labelInput {
        position: relative;
        top: 4px;
    }
    .labelInput {
        display: flex;
        gap: 3px;
        list-style: none;
        width: 100%;
        height: 10px;
        background-color: #FFF;
    }
    .labelInput .network {
        width: 14.08px;
        height: 14.08px;
        position: relative;
        bottom: 2px;
    }
    .netList {
        border-bottom-width: 1px;
        cursor: pointer;
        display: flex;
        gap: 2px;
        margin: auto 0;
        width: 100%;
        height: 30px;
        background-color: #FFF;
        transition: .3s;
    }
    .netList1 {
        border-bottom-width: 1px;
        cursor: pointer;
        display: flex;
        gap: 2px;
        padding: 8px;
        margin: auto 0;
        width: 100%;
        transition: .3s;
        z-index: 999;
    }
    .netList .netImage {
        width: 12px;
        overflow: hidden;
    }
    .netImage .NoImage {
        object-fit: cover;
        width: 12px;
    }
    .netList1 .netName1 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        text-align: left;
        color: #219250;
    }
    .netList .netName {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        text-align: left;
        color: #7C7C7C;
    }
    .input1 {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: 0;
        margin: auto 0;
        border-radius: 3.67px;
        width: 100%;
        height: 30px;
        border: 0.5px;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .input1 .head4 {
        position: relative;
        left: -9px;
        bottom: 7px;
    }
    .input1 span {
        position: relative;
        left: -5px;
    }
    .input1 .span2 {
        color: #219250;
        font-weight: 800;
        font-size: 10px;
        position: relative;
        top: -5px;
        left: -5px;
        width: 100%;
    }
    .input1 img {
        position: relative;
        top: 0;
        left: 0;
    }
    .input1 .head9 {
        width: 100%;
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0em;
        color: #7C7C7C;
        position: relative;
        top: -4px;
    }
    .input2 {
        display: flex;
        justify-content: space-between;
        margin: auto 0;
        border-radius: 3.67px;
        width: 100%;
        height: 30px;
        border: none;
        box-shadow: 0px 0px 4px 0px #00000040;
    }
    .input2 h2 {
        position: relative;
        bottom: 4px;
        left: 3px;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
    }
    .disc img {
        margin: auto 0;
        width: 14px;
        position: relative;
        bottom: 0;
        left: 0;
    }
    .input .phone {
        outline: none;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }
    .input .phones {
        outline: none;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
        position: relative;
        left: -30px;
    }
    .phone::placeholder {
        font-weight: 400;
    }
    .call img {
        margin: auto 0;
        width: 12px;
        height: 14px;
        position: relative;
        left: 5px;
    }
    .span3 {
        font-size: 7px;
        font-weight: 700;
        line-height: 9px;
        letter-spacing: 0em;
        text-align: left;
        color: #04177F;
    }
    .span4 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        color: #7C7C7C;
    }
    .head3 {
        color: #000000;
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        text-align: left;
    }
    .head4 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
        color: #7C7C7C;
        position: relative;
        top: -3px;
        left: 0;
    }
    .labelInput .head4 {
        margin: auto 0 30px;
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0em;
        color: #7C7C7C;
        position: relative;
        top: -8px;
    }
    .add {
        display: flex;
        gap: 10px;
        width: 100%;
        height: 14px;
        margin: 0 auto;
        position: relative;
        left: 0;
        top: 10px;
    }
    .add h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 20.8px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        top: -7px;
    }
    .containFlex2 {
        width: 100%;
        height: 38px;
        position: relative;
        top: 50px;
        margin: 0 auto;
    }
    .containFlex3 {
        width: 100%;
        height: 38px;
        position: relative;
        top: 30px;
        margin: 0 auto;
    }
    .FlexPut2 {
        padding: 8px 36px;
        margin: auto;
        border-radius: 5px;
        width: 100%;
        height: 43px;
        font-size: 11px;
        border: none;
        background: #04177F;
        box-shadow: 0px 0px 4px 0px #00000040;
        color: #FFF;
    }
    .help {
        margin: 0 auto;
        width: 100%;
        height: 14.73px;
        display: flex;
        justify-content: center;
        gap: 14.9px;
        position: relative;
        top: 0;
        padding-bottom: 25%;
    }
    .help h2 {
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0em;       
    }
    .help .btnContact {
        background: #04177F;
        padding: 2.865px 8.594px;
        height: 14.73px;
        border-radius: 5.156px;
        color: #fff;
        font-size: 8px;
        font-weight: 500;
        line-height: 9px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        bottom: 1px;
    }
    .airtimeTop {
        padding-bottom: 15%;
        gap: 25px;
    }
    .AirtimeTops {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 350px;
        height: 100%;
        margin: 0 auto;
    }
    .AirtimeTops1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        height: 100vh;
    }
    .confirmPro {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 0.3);
        z-index: 300;
        justify-content: center;
        align-items: center;
        overflow-y: auto;
    }
    .processFirm {
        background-color: #fff;
        border: none;
        margin-left: 15%;
        margin-right: 15%;
        padding-bottom: 20px;
        border-radius: 8px;
        position: relative;
        border-radius: 11.5px;
    }
    .modalHeader {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        border-bottom: 10px solid #04177F;
        padding-right: 12px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .closeBtn {
        width: 15px;
        height: 15px;
        font-size: 12px;
        padding-bottom: 3px;
        border-radius: 50%;
        border: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #92ABFE;
        color: #7C7C7C;
        border: none;
    }
    .modalContent {
        padding: 15px;
        padding-left: 12px;
    }
    .modalContent .Tran {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .Tran h2 {
        font-size: 6px;
    }
    .modalContent h2 {
        text-align: center;
        font-size: 8px;
        font-weight: 600;
        line-height: 10px;
        letter-spacing: 0em;
    }
    .section {
        display: flex;
        justify-content: space-between;
        margin-bottom: .5%;
    }
    .section .head2 {
        color: #7C7C7C;
    }
    .flexWide {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px;
    }
    .flexGap {
        display: flex;
        gap: .25rem;
    }
    .nairaFlex {
        width: 100%;
        padding: 0 12px;
        height: 60px;
        display: flex;
        align-items: center;
        gap: 2px;
        background: #F6F7F7;    
    }
    .flexBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5%;
    }
    .flexBtn button {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        padding: 4px 6px;
        background-color: #04177F;
        color: #FFF;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
    }
}