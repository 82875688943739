.inputBoxShadow {
  box-shadow: 0px 0px 2.31667px rgba(0, 0, 0, 0.25);
}
.inputBoxShadow2 {
  box-shadow: 0px 0px 2.31667px rgba(0, 0, 0, 0.166);
}

/* Small screens (up to 767px) */
@media (max-width: 767px) {
}

/* Tablets (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
}

/* Desktops (1024px and above) */
@media (min-width: 1024px) {
}
