/* ===============Mobile Screen================= */
.quickbody {
  margin-top: 5%;
}

.QuickFeaturetext {
  font-weight: bolder;
}

.InOutText {
  font-size: 11px;
  font-weight: bolder;
}

.feature {
  display: flex;
  width: 60.333px;
  padding: 3.333px 6.667px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 3.333px;
  border-radius: 4px;
  box-shadow: 0px 0px 3.3333332538604736px 0px rgba(0, 0, 0, 0.25);
  font-size: 8.333px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Chart {
  padding-top: 2%;
  padding-right: 2%;
  box-shadow: 0px 0px 6.6666669845581055px 0px rgba(0, 0, 0, 0.25);
}

.chartbuttons {
  display: flex;
  float: right;
}

.chartBtn {
  width: auto;
  padding: 2px 3.333px;
  gap: 3.333px;
  border-radius: 1.667px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.INnOUT {
  justify-content: space-between;
}

.inflowOutflow {
  padding: 4.924px 6.132px;
  border-radius: 1.97px;
}

.totalTransactions {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.filter {
  box-shadow: 0px 1.3290735483169556px 1.3290735483169556px 0px
    rgba(0, 0, 0, 0.25);
}

.viewTransact {
  box-shadow: 0px 0px 6.666667461395264px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.viewTransactions {
  display: flex;
  padding: 20px 0px 15px 0px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.noTransactions {
  width: 172.333px;
  height: 152px;
}

.viewAll {
  display: flex;
  padding: 0.999px 1.998px;
  align-items: center;
  gap: 1.665px;
  border: 0.1px solid var(--stroke-fade, rgba(0, 0, 0, 0.3));
  background: #fff;
  box-shadow: 0px 0px 1.3319103717803955px 0px rgba(0, 0, 0, 0.25);
}

.viewText {
  color: #707070;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 13px */
}

.contactus {
  display: flex;
  padding: 2.865px 8.594px;
  justify-content: center;
  align-items: center;
  border-radius: 5.156px;
  color: #fff;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 10.4px */
}

.calender {
  position: absolute;
  width: 170px;
  height: 209px;
  background: #fff;
  box-shadow: 0px 0px 15.634516716003418px 0px rgba(139, 67, 255, 0.5);
  margin-top: -8%;
}

.toMyAcct {
  display: flex;
  padding: 6.968px 12.508px 10.376px 6.968px;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 3.974px;
  box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.cryptoTopUp {
  position: absolute;
  top: 34%;
  width: 90%;
  height: 269px;
  border-radius: 8px;
  box-shadow: 0px 0px 9.217082977294922px 0px rgba(0, 0, 0, 0.25);
}

.countrySelect {
  border-radius: 5px;
  color: #7c7c7c;
  position: absolute;
  /* left: 70%; */
  right: 5;
  width: 95px;
  box-shadow: 0px 1.627705693244934px 4.0692644119262695px 0px
    rgba(0, 0, 0, 0.25);
}

.accountRecords {
  display: inline-flex;
  height: 250px;
  width: 100%;
  margin-top: 5%;
  flex-direction: column;
  align-items: center;
  gap: 25.333px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 1.333333134651184px 1.333333134651184px 0px
    rgba(0, 0, 0, 0.25);
}

.accountRecords {
  display: inline-flex;
  width: 100%;
  margin-top: 5%;
  background: #fff;
  box-shadow: 0px 1.333333134651184px 1.333333134651184px 0px
    rgba(0, 0, 0, 0.25);
  flex-direction: column;
  align-items: center;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 6%;
  font-weight: bolder;
}

th {
  border: 0.5px solid #0003;
  border-bottom: hidden;
  border-top: hidden;
  text-align: center;
  padding: 4px;
}

td {
  border: 0.5px solid #0003;
  padding: 18px 6px;
}

.currencyUnavailable {
  height: 340px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .currencyUnavailable {
    height: 370px;
  }
}

@media (min-width: 768px) {
  .cryptoTopUp {
    position: absolute;
    top: 40%;
    width: 50%;
    height: 380px;
    border-radius: 8px;
    box-shadow: 0px 0px 9.217082977294922px 0px rgba(0, 0, 0, 0.25);
  }
}

/* ===================Tablet view ================ */
@media (min-width: 820px) and (max-width: 1180px) {
  .feature {
    width: 130px;
    gap: 3.333px;
    padding: 15px 0px;
    font-size: 10.333px;
  }

  .feature img {
    width: 40px;
    height: 40px;
  }

  .InOutText {
    font-size: 14px;
  }

  .INnOUT {
    align-items: center;
  }

  .inflowOutflow {
    font-size: 12.145px;
    padding: 11.386px 20.361px;
    border-radius: 5px;
  }

  .chartBtn {
    padding: 6px 7.333px;
  }

  .totalTransactions {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
  }

  .viewTransactions {
    display: flex;
    width: 100%;
    padding: 20px 0px 15px 0px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding-bottom: 2%;
  }

  .noTransactions {
    width: 300px;
    height: 300px;
  }

  .viewAll {
    display: flex;
    padding: 5.999px 5.998px;
    align-items: center;
    gap: 1.665px;
    border: 0.1px solid var(--stroke-fade, rgba(0, 0, 0, 0.3));
    background: #fff;
    box-shadow: 0px 0px 1.3319103717803955px 0px rgba(0, 0, 0, 0.25);
  }

  .viewText {
    color: #707070;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 13px */
  }

  .contactus {
    display: flex;
    padding: 2.865px 8.594px;
    justify-content: center;
    align-items: center;
    gap: 5.729px;
    border-radius: 5.156px;
    font-size: 18px;
  }

  .calender {
    margin-top: -3.5%;
    width: 270px;
    height: 260px;
  }

  .cryptoTopUp {
    position: absolute;
    top: 40%;
    width: 50%;
    height: 380px;
    border-radius: 8px;
    box-shadow: 0px 0px 9.217082977294922px 0px rgba(0, 0, 0, 0.25);
  }

  .countrySelect {
    border-radius: 5px;
    width: 140px;
  }

  .accountRecords {
    height: 100%;
  }

  th {
    padding: 6px;
  }

  td {
    text-align: center;
    border: 0.5px solid #0003;
    padding: 18px 18px;
  }
}

/* ==================Surface Pro 7================ */
@media (min-width: 912px) and (max-width: 1368px) {
  .feature {
    width: 150px;
    padding: 14.333px 6.667px;
    border-radius: 9px;
    box-shadow: 0px 0px 8.3333332538604736px 0px rgba(0, 0, 0, 0.25);
    font-size: 13.333px;
  }

  .QuickFeaturetext {
    font-size: 18px;
  }

  .feature img {
    width: 40px;
    height: 40px;
  }

  .quickgrid {
    gap: 20px;
  }

  .InOutText {
    font-size: 18px;
  }

  .inflowOutflow {
    font-size: 16.145px;
    padding: 11.386px 25.361px;
    border-radius: 7px;
  }

  .totalTransactions {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
  }

  .totalTransactions li {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .calender {
    margin-top: -3.5%;
    width: 270px;
    height: 260px;
  }

  .toMyAcct {
    display: flex;
    padding: 8.594px 28.645px 8.375px 8.595px;
    align-items: center;
    gap: 203.073px;
    border-radius: 6.875px;
    box-shadow: 0px 0px 5.7291669845581055px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  .cryptoTopUp {
    position: absolute;
    top: 37%;
    width: 50%;
    height: 360px;
    border-radius: 8px;
    box-shadow: 0px 0px 9.217082977294922px 0px rgba(0, 0, 0, 0.25);
  }
}

/* ==================Surface Duo=================== */
@media (min-width: 540px) and (max-width: 720px) {
  .feature {
    width: 85px;
  }

  .quickgrid {
    gap: 20px;
  }
}

/* ================Desktop View======================= */
@media (min-width: 1024px) {
  .feature {
    display: flex;
    width: 95%;
    padding: 13.248px 26.496px;
    flex-direction: column;
    align-items: center;
    gap: 13.248px;
    border-radius: 15.897px;
    box-shadow: 0px 0px 13.247862815856934px 0px rgba(0, 0, 0, 0.25);
    font-size: 18.197px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .feature img {
    width: 75px;
    height: 75px;
  }

  .quickgrid {
    gap: 24px;
  }

  .InOutText {
    font-size: 21px;
  }

  .InOutText {
    font-size: 21px;
    font-weight: bolder;
  }

  .inflowOutflow {
    font-size: 21px;
    font-weight: 800;
    padding: 19.873px 52.994px;
  }

  .chartBtn {
    padding: 2px 13.259px;
    border-radius: 6.629px;
  }
  .Chart {
    height: 550px;
  }

  .filter {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .viewText {
    font-size: 14px;
  }

  .noTransactions {
    width: 400px;
    height: 400px;
  }

  .contactus {
    font-size: 15px;
  }

  .calender {
    position: absolute;
    width: 300px;
    height: 340px;
    box-shadow: 0px 0px 15.634516716003418px 0px rgba(139, 67, 255, 0.5);
    margin-top: -3.5%;
  }

  .toMyAcct {
    display: flex;
    padding: 15px 50px 15px 15px;
    align-items: center;
    gap: 354px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }

  .cryptoTopUp {
    width: 562px;
    height: 500px;
    position: absolute;
    top: 5%;
  }

  .countrySelect {
    border-radius: 5px;
    width: 231px;
  }

  td {
    text-align: center;
    border: 0.5px solid #0003;
    padding: 18px 18px;
  }

  .accountRecords {
    height: 100%;
  }

  .currencyUnavailable {
    height: 540px;
  }
}
