/* @font-face {
  font-family: interFont;
  src: url(./font/Inter/static/Inter-Regular.ttf);
} */
/* body,
html {
  font-family: interFont;
} */

.react-calendar {
  width: 750px;
  max-width: 100%;
  background: white;
  padding: 2%;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 10px;
  margin-bottom: 1em;
  font-size: 12px;
}

.react-calendar__navigation button {
  max-width: 100%;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  /* text-transform: uppercase; */
  font-weight: bold;
  font-size: 0.25em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 1.5em;
  /* background-color: red; */
  /* here */
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.1em;
  font-weight: bold;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
} */

.react-calendar__month-view__days__day--neighboringMonth {
  color: #7575754c;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 4em 0.5em;
}

.react-calendar__tile {
  /* max-width: 100%; */
  /* padding: 10px 6.6667px; */
  /* background: none; */
  /* text-align: center; */
  line-height: 20px;
  font-size: 12px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

/* .react-calendar__tile--now {
  background: #ffff76;
} */

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
  border-radius: 50px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* ================Desktop View============== */
@media (min-width: 1240px) {
  .react-calendar {
    width: 650px;
    max-width: 100%;
    background: white;
    padding: 2%;
    /* border: 1px solid #a0a096; */
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }

  .react-calendar__month-view__weekdays {
    font-size: 0.7em;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 1.1em;
  }

  .react-calendar__tile--active {
    border-radius: 100px;
  }

  .react-calendar__tile {
    /* max-width: 100%; */
    padding: 10px 6.6667px;
    /* background: none; */
    /* text-align: center; */
    line-height: 16px;
    font-size: 14px;
  }

  .react-calendar__navigation {
    display: flex;
    height: 40px;
    margin-bottom: 1em;
  }

  .react-calendar__navigation {
    font-size: 16px;
  }
}
/* Referral code starts here */
.rectangle1{
  background: linear-gradient(92deg, #92ABFE 4.68%, #404EDE 55.04%);

}
 .Refer table td,th{
  border: none;
} 
/* and ends here */
/* Education code starts here */
.educationPinsHero{
  background: var(--Gradiant, linear-gradient(90deg, #60FF52 0%, rgba(250, 232, 68, 0.90) 50.97%, #FF5A7F 100%));
}
.Exam-box{
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--stroke-fade, rgba(0, 0, 0, 0.30));
}
@keyframes openDropDown {
  from {
   transform : rotate(0deg)
  }to{
   transform : rotate(180deg)
  }
}
.DropIt {
  animation : openDropDown 0.3s ease 1 forwards;
}
.ProfileHero{
  background: linear-gradient(91deg, rgba(29, 255, 255, 0.84) 6.85%, #AC43FF 102.32%);

}
.activeTab{
border-bottom : 2px solid #04177F;
border-radius : 10px;
  }
  .RecipientHero{
    background: linear-gradient(91deg, #367CFF 0.39%, #69FFD2 101.71%);
  }
  .bvnQuery{
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  .bvnQuery::-webkit-scrollbar{
    width : 0px;
  }
  .bnvQuery::-webkit-scrollbar-thumb{
    background-color: transparent;
  }
  .idVerify{
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  .idVerify::-webkit-scrollbar{
    width : 0px;
  }
  .idVerify::-webkit-scrollbar-thumb{
    background-color: transparent;
  }
  .confirmRecipient{
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }
  .confirmRecipient::-webkit-scrollbar{
    width : 0px;
  }
  .confirmRecipient::-webkit-scrollbar-thumb{
    background-color: transparent;
  }
  .deleteRecipientSuccess{
    overflow-y: scroll;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }
  .deleteRecipientSuccess::-webkit-scrollbar{
    width : 0px;
  }
  .deleteRecipientSuccess::-webkit-scrollbar-thumb{
    background-color: transparent;
  }
  
