
.hero {
	/* position: ; */
	top: 0;
	background: linear-gradient(
	  -65deg,
	  rgba(23, 102, 238, 0.295),
	  #ffdbde82,
	  #1fddff73,
	  #ff85ae7c,
	  rgba(107, 102, 255, 0.536),
	  #ffb58a7f,
	  #19dfdf86
	);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
	height: 750px;
	margin-top: -20%;
	/* width: 100%; */
	/* opacity: 50%; */
  }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media (min-width: 768px) {
	.hero {
		top: 0;
		background: linear-gradient(-155deg, rgba(23, 102, 238, 0.295), #ffdbde82, #1fddff73, #ff85ae7c, rgba(107, 102, 255, 0.536), #ffb58a7f,  #19dfdf86);
		background-size: 400% 400%;
		animation: gradient 10s ease infinite;
		height: 680px;
		margin-top: -20%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 6%;
		gap:20px
	}
}

@media (min-width: 1024px) {
	.hero {
		top: 0;
		background: linear-gradient(-155deg, rgba(23, 102, 238, 0.295), #ffdbde82, #1fddff73, #ff85ae7c, rgba(107, 102, 255, 0.536), #ffb58a7f,  #19dfdf86);
		background-size: 400% 400%;
		animation: gradient 10s ease infinite;
		height: 900px;
		margin-top: -20%;
		display: flex;
		justify-content: center;
		/* align-items: center; */
		padding: 6% 8%;
		gap:20px;
		/* background-color: red; */
	}
}