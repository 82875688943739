.successful::-webkit-scrollbar {
  display: none;
}
.transferMoneyPop::-webkit-scrollbar {
  display: none;
}
.aremxyMoneyPop::-webkit-scrollbar {
  display: none;
}
.transferConfirmation::-webkit-scrollbar {
  display: none;
}
.successfulTwo::-webkit-scrollbar {
  display: none;
}
.checkrate::-webkit-scrollbar {
  display: none;
}
.conversionrate::-webkit-scrollbar {
  display: none;
}
.checkrate::-webkit-scrollbar {
  display: none;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.currencySelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  
}

.currencySelect::-ms-expand {
  display: none;
}

.hideArrows {
  -moz-appearance: textfield;
}

/* Style to hide arrows in Firefox */
.hideArrows::-webkit-inner-spin-button,
.hideArrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Style to hide arrows in other browsers */
.hideArrows[type="number"] {
  -moz-appearance: textfield;
}

th {
  border: none;
}

td {
  border: none;
  border-bottom: 0.5px solid #0003;
}

.transferMoneyBtn {
  border-radius: 4.763px;
  background: #fff;
  box-shadow: 0px 0px 3.969465970993042px 0px rgba(0, 0, 0, 0.25);
}

.boxes {
  border-radius: 3.328px;
  border: 0.555px solid var(--stroke-fade, rgba(0, 0, 0, 0.3));
  background: #fff3e5;
  grid-column: 0;
}

.detailsBox {
  display: grid;
  gap: 10px;
}

.transferMoneyPop {
  position: absolute;
  height: 505px;
  flex-shrink: 0;
  bottom: 0%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.aremxyMoneyPop {
  position: absolute;
  height: 445px;
  flex-shrink: 0;
  bottom: 0%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.transferConfirmation {
  position: absolute;
  bottom: 0%;
  height: 470px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}
.contactTeam {
  bottom: 0%;
  flex-shrink: 0;

  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.checkrate {
  height: 550px;
  /* width: 90%; */
  flex-shrink: 0;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}
.conversionrate {
  height: 450px;
  /* width: 90%; */
  flex-shrink: 0;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.redeemrate {
  height: 500px;
  /* width: 90%; */
  flex-shrink: 0;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.conversionrate {
  height: 490px;
  /* width: 90%; */
  flex-shrink: 0;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.inputPin {
  position: absolute;
  bottom: 0%;
  height: 250px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.deletePopUp {
  height: 250px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.deleteSuccess {
  height: 250px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
}

.inputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.successful {
  /* position: absolute;
   bottom: 0%; */
  height: 250px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}

.successfulTwo {
  position: absolute;
  bottom: 0%;
  height: 470px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}

.failed {
  position: absolute;
  bottom: 0%;
  height: 250px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}

.receipt {
  height: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 5.952381610870361px 0px rgba(0, 0, 0, 0.25);
}

.contactus {
  display: flex;
  padding: 2.865px 8.594px;
  justify-content: center;
  align-items: center;
  border-radius: 5.156px;
  color: #fff;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 10.4px */
}

/* =====================Tablet View ====================== */
@media (min-width: 820px) and (max-width: 1180px) {
  .detailsBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }

  .transferMoneyPop {
    height: 620px;
    border-radius: 12px;
    bottom: 30%;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .aremxyMoneyPop {
    height: 550px;
    border-radius: 12px;
    background: #fff;
    bottom: auto;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }
  .contactTeam {
    top: 20%;
    flex-shrink: 0;

    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .transferConfirmation {
    height: 572px;
    bottom: 30%;
    flex-shrink: 0;
    border-radius: 11px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }
  .contactTeam {
    bottom: 25%;
    flex-shrink: 0;

    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .inputPin {
    bottom: auto;
    top: auto;
    width: 481.25px;
    height: 350px;
  }

  .successful {
    height: 400px;
  }

  .successfulTwo {
    top: auto;
    bottom: auto;
    width: 60%;
    height: 600px;
    border-radius: 15px;
  }

  .failed {
    bottom: 30%;
    width: 80%;
    height: 350px;
  }

  .deletePopUp {
    height: 400px;
  }

  .deleteSuccess {
    height: 400px;
  }

  .boxes {
    background: #fff3e5;
    border-radius: 3.328px;
    border: 0.555px solid var(--stroke-fade, rgba(0, 0, 0, 0.3));
  }

  .checkrate {
    height: 750px;
    border-radius: 15px;
  }
}

/* ==================Laptop View ============================ */
@media (min-width: 1024px) {
  .accountRecords {
    height: 100%;
  }

  .transferMoneyBtn {
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }

  .detailsBox {
    display: grid;
    /* flex-direction: column; */
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
  }
  .contactTeam {
    top: 20%;
    flex-shrink: 0;

    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }
  .redeemrate {
    height: 600px;
    /* width: 90%; */
    flex-shrink: 0;
    border-radius: 11px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .boxes {
    background: #fff3e5;
    border-radius: 6.328px;
    border: 1.555px solid var(--stroke-fade, rgba(0, 0, 0, 0.3));
  }

  .transferMoneyPop {
    height: 550px;
    border-radius: 20px;
    bottom: 7%;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .aremxyMoneyPop {
    height: 500px;
    border-radius: 20px;
    background: #fff;
    bottom: auto;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .successful {
    /* position: absolute;
    top: 10%; */
    border-radius: 15px;
    height: 465px;
    box-shadow: 0px 0px 16.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }

  .successfulTwo {
    position: absolute;
    top: auto;
    bottom: auto;
    border-radius: 15px;
    height: 550px;
    box-shadow: 0px 0px 16.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }

  .failed {
    position: absolute;
    top: 7%;
    border-radius: 15px;
    height: 550px;
    box-shadow: 0px 0px 16.952381610870361px 0px rgba(0, 0, 0, 0.25);
  }

  .transferConfirmation {
    position: absolute;
    top: 7.5%;
    height: 550px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
  }

  .inputPin {
    height: 405px;
    position: absolute;
    bottom: auto;
    top: auto;
    border-radius: 20px;
  }

  .deletePopUp {
    border-radius: 20px;
    height: 465px;
  }

  .deleteSuccess {
    border-radius: 20px;
    height: 465px;
  }

  .receipt {
    border-radius: 20px;
  }

  .checkrate {
    height: 550px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    width: 560px;
  }

  .conversionrate {
    height: 569px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 7.068181991577148px 0px rgba(0, 0, 0, 0.25);
    width: 560px;
  }

  .contactus {
    display: flex;
    padding: 5.865px 8.594px;
    font-size: 14px;
  }
}
