#background{
    background: linear-gradient(92.3deg, #9899FF 4.68%, rgba(206, 67, 255, 0.63) 98.66%);
}
#fiatBackground{
    background: linear-gradient(92.3deg, #FF6E79 31.38%, #1FFFA8 98.66%);
}

.conversionLists{
    box-shadow: 0px 0px 11.495177268981934px 0px #00000040;
}

.confirmConversion::-webkit-scrollbar {
    display: none;
  }
.confirmTV::-webkit-scrollbar {
    display: none;
  }

.switch{
    padding: 4.91px 8.96px 4.91px 2.89px;
    width: 102.02px;
    height: 17.05px;
    border-radius: 3.47px;
}

.bluebutton{
    width: 70px;
    height: 23px;
    border-radius: 9px;
    display: flex;
}

.input, .available{
   -webkit-box-shadow: 0px 0px 2.2916667461395264px 0px #00000040;
    box-shadow: 0px 0px 2.2916667461395264px 0px #00000040;

}

/* .input::-ms-value{
 display: none;
} */

.input:focus{
   outline: 0;
}


.dropbtn {
    cursor: pointer;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content p{
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content p:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  



  /* Modal Content */
  .modal-content {
    background: white;
    width: 350px;
    height: 280px;
    margin: auto 20px;
    box-shadow: 0px 0px 10px 0px #00000040;
    border-radius: 8px;
  }

  .confirmConversion{
    background: white;
    box-shadow: 0px 0px 7.090909481048584px 0px #00000040;
    border-radius: 8px, 8px, 0px, 0px;
    bottom: 0;
    flex-shrink: 0;
    position: absolute;
    height: 470px;
    overflow-y: auto;
  }

  .confirmTV{
    background: white;
    box-shadow: 0px 0px 7.090909481048584px 0px #00000040;
    border-radius: 8px, 8px, 0px, 0px;
    bottom: 0;
    flex-shrink: 0;
    position: absolute;
    height: 520px;
    overflow-y: auto;
  }

  .InputPinToConvert{
    position: absolute;
    box-shadow: 0px 0px 10px 0px #00000040;
    border-radius: 8px, 8px, 0px, 0px;
    bottom: 0;
    flex-shrink: 0;
    background: #fff;
    height: 250px;
  }
        
.conversionLists{
  box-shadow: 0px 0px 4px 0px #00000040;
  height: 55px;
}

  /* Close Button */
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    display: none;
  }

.confirmConversion,.InputPinToConvert, .confirmTV{
  border-radius: 8px 8px 0 0;
}

.inputOTP {
  border: 0.7px solid  #0000004D; /* Initial border color */
  outline: none;          /* Remove the default focus outline */
  transition: border-color 0.5s; /* Add a transition effect for border-color */
}

.inputOTP:focus {
  border-color: #B200FF99;    /* Change the border color when focused */
}

@media only screen and (min-width: 768px) {
    .bluebutton{
        width: 75px;
        height: 24px;
        border-radius: 9px;
    }
    .modal-content {
        width: 430px;
        height: 350px;
        margin: auto;
        position: relative;
        border-radius: 6.88px;
      }
      .confirmConversion,.InputPinToConvert{
        border-radius: 12px;
      }
      
      .conversionLists{
        box-shadow: 0px 0px 5.7291669845581055px 0px #00000040;
        height: 65px;
      }

      .confirmConversion{
        box-shadow: 0px 0px 10px 0px #00000040;
        height: 572px;
        bottom: 25%;
        flex-shrink: 0;
        border-radius: 11px;
      }

      .confirmTV{
        box-shadow: 0px 0px 10px 0px #00000040;
        height: 592px;
        bottom: 25%;
        flex-shrink: 0;
        border-radius: 11px;
      }

      .InputPinToConvert{
        border-radius: 8px;
        bottom: 30%;
        width: 481.25px;
        height: 350px;
      }

}
@media only screen and (min-width: 1024px) {
    .bluebutton{
        width: 90px;
        height: 28px;
        border-radius: 10px;
    }
    .modal-content {
        width: 562px;
        height: 480px;
        margin: auto;
        position: relative;
        border-radius: 12px;
      }

      .conversionLists{
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.65);
        height: 90px;
      }
      .confirmConversion,.InputPinToConvert{
        border-radius: 20px;
      }
      .confirmConversion{
        box-shadow: 0px 0px 10px 0px #00000040;
        border-radius: 20px;
        top: 7%;
        height: 550px;
      }
      .confirmTV{
        box-shadow: 0px 0px 10px 0px #00000040;
        border-radius: 20px;
        top: 7%;
        height: 570px;
      }

      .InputPinToConvert{
        top: 18%;
        height: 405px;
        border-radius: 20px;
        width: 503px;
      }


}
