.bgClass{
    background: linear-gradient(302deg, #ebf0ff, #92abfe, #ced9ff, #61ccff, #d797f3, #ffcb89);
    background-size: 1200% 1200%;

    -webkit-animation: AnimationName 17s ease infinite;
    -moz-animation: AnimationName 17s ease infinite;
    animation: AnimationName 17s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}
@keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}



.myCardShadow{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.myButtonShadow{
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.myButton2{
    background: linear-gradient(92.26deg, #FFCD6C 4.03%, #FF6625 98.79%);
}

.featuresShadow{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}