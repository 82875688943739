.hero2{
    background-image: url("/public/Images/aboutimages/aboutusBG.png");
          background-repeat: "no-repeat";
          background-size:cover;
          background-position: "center";
          width: 100%;
          height: 451px;
          /* position: relative; */
          margin-top: -20%;
         
}


/* TABLET view */

@media (min-width: 768px){
    .hero2{
    background: url("/public/Images/aboutimages/aboutusBG.png");
    background-repeat: no-repeat;
    background-size:cover;
    /* background-position:  ; */
    width: 100%;
    height: 560px;
    margin-top: -20%;
    }
}


/* DESKTOP VIEW */

@media (min-width: 1024px){
    .hero2{
    background: url("/public/Images/aboutimages/altbg.png");
    background-repeat: no-repeat;
    background-size:contain;
    background-position: center;
    width: 100%;
    height: 900px;
    margin-top: -20%;
    }
}   