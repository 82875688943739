* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* First section */

.AirtimeTops {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 300px;
    height: 100%;
}
.airtimeTop {
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.airType {
    margin: 0 auto;
    width: 100%;
    height: 27px;
    cursor: pointer;
    display: flex;
    gap: 10px;
}
.airType h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #7C7C7C;
}
.airType img {
    width: 24px;
    width: 24px;
    position: relative;
    top: 3px;
}
.localInter {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    gap: 25px;
    align-items: center;
    border-bottom-width: 2px;
}
.localInter li {
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;
    text-align: center;
    width: 248px;
    height: 50px;
    padding: 10px 25px;
}
.active {
    border-bottom: 4px solid #04177F;
    border-radius: 6px;
    background: linear-gradient(0deg, #E2F3FF, #E2F3FF), linear-gradient(0deg, #04177F, #04177F);
    position: relative;  
}
.inactive {
    position: relative;
}
.NotInter {
    margin: 0 auto;
    padding-top: 2%;
    width: 562px;
    height: 500px;
    position: relative;
    top: 3%;
    display: flex;
    flex-direction: column;
    gap: 70px;
    background-color: #fff;
    border-radius: 12px;
}
.NotInter .timeAble {
    text-align: center;
    margin: 0 auto;
    position: relative;
}  
.timeAble h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #000000;
    width: 100%;
    text-align: center;
    margin: 0 auto 10px;
}
.timeAble h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #04177F;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}
.timeAble h5 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #04177F;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}
.NotInter .InterAirtime {
    margin: 0 auto;
    position: relative;
}
.InterAirtime img {
    width: 250px;
    height: 150px;
    margin: 0 auto;
}
.NotInter .InterAirtimeK {
    margin: 0 auto;
    position: relative;
}
.InterAirtimeK img {
    width: 250px;
    height: 200px;
    margin: 0 auto;
}
.NotInter .coming {
    text-align: center;
    margin: 0 auto;
    position: relative;
}
.coming h2 {
    position: relative;
    left: 210%;
    bottom: 25px;
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0em;
    color: #000000;
}
.coming .btnOk {
    border-radius: 5px;
    width: 100%;   
    padding: 5px 20px;
    background: #04177F;
    color: #fff;
    font-size: 20px;
}

/* For each Tab that's not ready */

.NotInterX {
    margin: 0 auto;
    padding-top: 2%;
    width: 562px;
    height: 500px;
    position: relative;
    top: 3%;
    display: flex;
    flex-direction: column;
    gap: 70px;
    background-color: #fff;
    border-radius: 12px;
}
.NotInterX .timeAble {
    text-align: center;
    margin: 0 auto;
    position: relative;
}  
.NotInterX .timeAbleK {
    text-align: center;
    margin: 40px auto 0;
    position: relative;
}  
.timeAbleX h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #000000;
    width: 100%;
    text-align: center;
    margin: 0 auto 10px;
}
.timeAbleX h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #04177F;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}
.timeAbleK h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #04177F;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}
.NotInterX .InterAirtimeX {
    margin: 0 auto;
    position: relative;
}
.InterAirtimeX img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
}
.NotInterX .comingX {
    text-align: center;
    margin: 0 auto;
    position: relative;
}
.comingX h2 {
    position: relative;
    left: 210%;
    bottom: 25px;
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0em;
    color: #000000;
}
.comingX .btnOkX {
    border-radius: 5px;
    width: 100%;  
    padding: 5px 20px;
    background: #04177F;
    color: #fff;
    font-size: 20px;
}

/* This is the Local Airtime section */

.containAir {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin: 0 auto;
}

/* Styling for TabOneItem */

.airtimeCol {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 15px 0;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.65);
}
.airtimeCol .airCol {
    position: relative;
    margin: auto 0;
    top: 0;
    left: 15px;
    display: flex;
    gap: 10px;
}
.airtimeCol .airImg {
    position: relative;
    width: 24px;
    height: 24px;
    margin: auto 0;
}
.airCol .airCon {
    position: relative;
    margin: auto 0;
    width: 20px;
    height: 20px;
}
.airText {
    display: flex;
    flex-direction: column;
    margin: auto 0;
    gap: 1px;
}
.airText h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #000000;
}
.airText p {
    font-size: 14.05px;
    font-weight: 500;
    line-height: 21.07px;
    letter-spacing: 0em;
    color: #7C7C7C;
}

/* Contact aspect of the airtime page */

.help {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    height: 20px;
    display: flex;
    gap: 26px;
}
.help h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 15.6px;
    letter-spacing: 0em;
}
.help .btnContact {
    background: #04177F;
    padding: 5px 15px 10px 15px;
    height: 20px;
    border-radius: 9px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 10.4px;
    letter-spacing: 0em;
    position: relative;
    bottom: 3px;
}

@media (max-width: 1024px) {
    .airtimeCol {
        box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
        border-width: 1px;
    }
}

@media (max-width: 768px) {
    .airType {
        margin: 0 auto;
        width: 100%;
        height: 15px;
        cursor: pointer;
        display: flex;
        gap: 5.87px;
        position: relative;
        top: 0;
    }
    .airType h2 {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #7C7C7C;
    }
    .airType img {
        width: 14px;
        height: 14px;
        position: relative;
        top: 1px;
    }
    .localInter {
        width: 100%;
        height: 29px;
        margin: 0 auto;
        display: flex;
        gap: 14.67px;   
        align-items: center;
        margin-bottom: 0;
        border-bottom-width: 2px;
        position: relative;
        top: 0;
    }
    .localInter li {
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
        text-align: center;
        width: 145px;
        height: 29px;
        padding: 5.87px 10.6px;
        border-radius: 3.52px;
    }
    .active {
        border-bottom: 3.5px solid #04177F;
        background: linear-gradient(0deg, #E2F3FF, #E2F3FF), linear-gradient(0deg, #04177F, #04177F);
        position: relative;  
    }
    .NotInter {
        margin: 0 auto;
        width: 329px;
        height: 237px;
        border-radius: 7px;
        position: relative;
        top: 0;
        left: 0;
        gap: 25px;
    }
    .NotInter .timeAble {
        text-align: center;
        margin: 0 auto;
        position: relative;
        left: 0;
    }  
    .timeAble h2 {
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        color: #000000;
        width: 100%;
        margin: 0 auto 10px;
    }
    .timeAble h3 {
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        color: #04177F;
        width: 100%;
        margin: 0 auto;
    }
    .timeAble h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        color: #04177F;
        width: 100%;
        margin: 0 auto;
    }
    .NotInter .InterAirtime {
        margin: 0 auto;
        position: relative;
        left: 0;
    }
    .InterAirtime img {
        width: 146.12px;
        height: 88px;
        margin: 0 auto;
    }
    .NotInter .InterAirtimeK {
        margin: 0 auto;
        position: relative;
        left: 0;
    }
    .InterAirtimeK img {
        width: 146.12px;
        height: 100px;
        margin: 0 auto;
    }
    .NotInter .coming {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        position: relative;
        left: 0;
    }
    .coming h2 {
        position: relative;
        left: -10px;
        top: 0;
        font-size: 6px;
        font-weight: 600;
        line-height: 9px;
        letter-spacing: 0em;
        text-align: right;
        color: #000000;
    }
    .coming .btnOk {
        border-radius: 5.87px;
        width: 25%;
        height: 20px;   
        padding: 2.93px 14.67px;
        background: #04177F;
        color: #fff;
        font-size: 10px;
    }  
    .NotInterX {
        margin: 0 auto;
        width: 329px;
        height: 237px;
        border-radius: 7px;
        position: relative;
        top: 0;
        left: 0;
        gap: 20px;
    }
    .NotInterX .timeAbleX {
        text-align: center;
        margin: 0 auto;
        left: 0;
    }  
    .NotInterX .timeAbleK {
        text-align: center;
        margin: 10px auto 0;
        left: 0;
    }  
    .timeAbleX h2 {
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        color: #000000;
        width: 100%;
        margin: 0 auto 10px;
    }
    .timeAbleX h3 {
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        color: #04177F;
        width: 100%;
        margin: 0 auto;
    }
    .timeAbleK h3 {
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        color: #04177F;
        width: 100%;
        margin: 0 auto;
    }
    .NotInterX .InterAirtimeX {
        margin: 0 auto;
        top: 0;
        left: 0;
    }
    .InterAirtimeX img {
        width: 100px;
        height: 100px;
        margin: 0 auto;
    }
    .NotInterX .comingX {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        left: 0;
    }
    .comingX h2 {
        position: relative;
        left: -10px;
        top: 0;
        font-size: 6px;
        font-weight: 600;
        line-height: 9px;
        letter-spacing: 0em;
        text-align: right;
        color: #000000;
        width: 100%;
    }
    .comingX .btnOkX {
        border-radius: 5.87px;
        width: 25%;
        height: 20px;   
        padding: 2.93px 14.67px;
        background: #04177F;
        color: #fff;
        font-size: 10px;
    }  
    .containAir {
        display: flex;
        flex-direction: column;
        gap: 14.67px;
        width: 100%;
        height: 293px;
        margin: 0 auto;
    }
    .airtimeCol {
        width: 100%;
        height: 46px;
        margin: 0 auto;
        display: flex;
        padding: 0 30px 0 0;
        justify-content: space-between;
        border: none;
        border-radius: 7px;
        box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
    }
    .airtimeCol .airCol {
        position: relative;
        top: 0;
        left: 8.8px;
        display: flex;
        gap: 5.87px;
        width: 407px;
        height: 29px;
    }
    .airtimeCol .airImg {
        position: relative;
        width: 14px;
        height: 14px;
        top: 0;
    }
    .airCol .airCon {
        position: relative;
        margin: auto 0;
        width: 11.74px;
        height: 11.74px;
    }
    .airText {
        display: flex;
        flex-direction: column;
        margin: auto 0;
        gap: 2.93px;
        width: 95%;
        height: 29px;
    }
    .airText h2 {
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
    }
    .airText p {
        font-size: 8px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #7C7C7C;
    }
    .help {
        margin: 0 auto;
        width: 100%;
        padding-bottom: 10%;
        justify-content: center;
        height: 20px;
        display: flex;
        gap: 26px;
    }
    .help h2 {
        font-size: 8px;
        font-weight: 500;
        line-height: 15.6px;
        letter-spacing: 0em;
    }
    .help .btnContact {
        background: #04177F;
        padding: 5px 15px 10px 15px;
        height: 20px;
        border-radius: 9px;
        color: #fff;
        font-size: 8px;
        font-weight: 500;
        line-height: 10.4px;
        letter-spacing: 0em;
        position: relative;
        bottom: 3px;
    }
}

@media (max-width: 425px) {
    .airtimeTop {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    .AirtimeTops {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    .airType {
        width: 100%;
        height: 12px;
        display: flex;
        gap: 8px;
        margin: 0 auto;
        top: 0;
        bottom: 0;
    }
    .airType h2 {
        font-size: 10px;
        font-weight: 500;
        line-height: 9px;
        letter-spacing: 0em;
        text-align: left;
    }
    .airType img {
        width: 12px;
        height: 12px;
        position: relative;
        top: -1px;
    }
    .localInter {
        width: 100%;
        height: 16px;
        display: flex;
        gap: 8.09px;
        margin: 0 auto;
        border-bottom-width: 2px;
        position: relative;
        top: 0;
        bottom: 0;
    }
    .localInter li {
        width: 100%;
        height: 20px;
        font-size: 8px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        padding: 3.24px 9.71px;
        text-align: center;
        margin: 0 auto;
        border-radius: 5px;
    }
    .active {
        border-bottom: 3px solid #04177F;
        background: linear-gradient(0deg, #E2F3FF, #E2F3FF), linear-gradient(0deg, #04177F, #04177F);
        position: relative;  
    }
    .containAir {
        width: 100%;
        gap: 20px;
        position: relative;
        bottom: 10px;
    }
    .airtimeCol {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 10px 0;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 0px 3.3119657039642334px 0px rgba(0, 0, 0, 0.25);
    }
    .airtimeCol .airCol {
        position: relative;
        top: 0;
        margin: auto 0;
        left: 10px;
        display: flex;
        gap: 10px;
        width: 80%;
        height: 33px;
    }
    .airtimeCol .airImg {
        position: relative;
        width: 12px;
        height: 12px;
        top: 0;
    }
    .airCol .airCon {
        position: relative;
        margin: auto 0;
        width: 20px;
        height: 20px;
    }
    .airText {
        display: flex;
        flex-direction: column;
        margin: auto 0;
        gap: 1.67px;
        width: 221.33px;
        height: 33px;
    }
    .airText h2 {
        font-size: 9px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }
    .airText p {
        font-size: 8px;
        font-weight: 500;
        line-height: 11px;
        letter-spacing: 0em;
        text-align: left;
        color: #7C7C7C;
        width: 221.33px;
        height: 7px;
    }
    .help {
        margin: 0 auto;
        width: 100%;
        height: 14.73px;
        display: flex;
        justify-content: center;
        gap: 14.9px;
        position: relative;
        top: 0;
        padding-bottom: 20%;
    }
    .help h2 {
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0em;       
    }
    .help .btnContact {
        background: #04177F;
        padding: 2.865px 8.594px;
        height: 14.73px;
        border-radius: 5.156px;
        color: #fff;
        font-size: 8px;
        font-weight: 500;
        line-height: 9px;
        letter-spacing: 0em;
        text-align: left;
        position: relative;
        bottom: 1px;
    }
    .NotInter {
        margin: 0 auto;
        width: 90%;
        height: 269px;
        border-radius: 8px;
        gap: 40px;
    }
    .NotInter .timeAble {
        text-align: center;
        margin: 0 auto; 
        width: 100%;
        position: relative;
        left: 0;
    }  
    .timeAble h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #000000;
        margin: 0 auto 10px;
    }
    .timeAble h3 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #04177F;
        margin: 0 auto;
    }
    .timeAble h5 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #04177F;
        margin: 0 auto;
    }
    .NotInter .InterAirtime {
        margin: 0 auto;
        position: relative;
        left: 0;
    }
    .InterAirtime img {
        width: 146px;
        height: 88.02px;
        margin: 0 auto;
    }
    .NotInter .InterAirtimeK {
        margin: 0 auto;
        position: relative;
        left: 0;
    }
    .InterAirtimeK img {
        width: 146px;
        height: 100.02px;
        margin: 0 auto;
    }
    .NotInter .coming {
        text-align: center;
        margin: 0 auto;
        position: relative;
        left: 0;
        width: 100%;
    }
    .coming h2 {
        position: relative;
        text-align: right;
        left: -10px;
        top: -12px;
        width: 100%;
        font-size: 8px;
        font-weight: 600;
        line-height: 9px;
        letter-spacing: 0em;
        color: #000000;
    }
    .coming .btnOk {
        position: relative;
        left: 0;
        border-radius: 5px;
        width: 90%;
        height: 40px;   
        padding: 2.93px 14.67px;
        background: #04177F;
        color: #fff;
        font-size: 10px;
        margin: 0 auto;
    }
    .NotInterX {
        margin: 0 auto;
        width: 90%;
        height: 269px;
        border-radius: 8px;
        gap: 30px;
    }
    .NotInterX .timeAbleX {
        text-align: center;
        margin-top: 0 auto; 
        width: 100%;
        position: relative;
        left: 0;
    }  
    .NotInterX .timeAbleK {
        text-align: center;
        margin-top: 15px; 
        width: 100%;
        position: relative;
        left: 0;
    }  
    .timeAbleX h2 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #000000;
        margin: 0 auto 10px;
    }
    .timeAbleX h3 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #04177F;
        margin: 0 auto;
    }
    .timeAbleK h3 {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0em;
        color: #04177F;
        margin: 0 auto;
    }
    .NotInterX .InterAirtimeX {
        margin: 0 auto;
        position: relative;
        left: 0;
        top: 0;
    }
    .InterAirtimeX img {
        width: 110px;
        height: 105px;
        margin: 0 auto;
    }
    .NotInterX .comingX {
        text-align: center;
        margin: 0 auto;
        position: relative;
        left: 0;
        width: 100%;
    }
    .comingX h2 {
        position: relative;
        text-align: right;
        left: -10px;
        top: -12px;
        width: 100%;
        font-size: 8px;
        font-weight: 600;
        line-height: 9px;
        letter-spacing: 0em;
        color: #000000;
    }
    .comingX .btnOkX {
        position: relative;
        left: 0;
        border-radius: 5px;
        width: 90%;
        height: 40px;   
        padding: 2.93px 14.67px;
        background: #04177F;
        color: #fff;
        font-size: 10px;
        margin: 0 auto;
    }
}