.top {
    background: linear-gradient(91deg, rgba(29, 255, 255, 0.42) 0.69%, rgba(77, 126, 255, 0.50) 95.47%);
}

.bottom {
    box-shadow: 0px 2.77333px 2.77333px 0px rgba(0, 0, 0, 0.25);
}

.top1 {
    background: linear-gradient(91deg, rgba(255, 218, 68, 0.50) 0.69%, rgba(47, 222, 121, 0.50) 95.47%);

}

.top2 {
    background: linear-gradient(91deg, rgba(172, 67, 255, 0.50) 0.69%, rgba(77, 126, 255, 0.50) 95.47%);

}

@media (min-width: 768px) {
    
}


@media (min-width: 845px) {
    .business {
        height: 120px;
    }
}

@media (min-width: 1020px) {
    .business {
        height: 160px;
    }
}

@media (min-width: 1440px) {
       .business {
        height: 250px;
    }
}

